import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import Header from "../../components/Header/StellarHeader/Header";
import resources from './resources.json';
import ResourceHubHeroGalaxy from "../../assets/images/hubelement.svg";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import DocumentIcon from "../../assets/images/DocumentIcon.svg"
import VideoIcon from "../../assets/images/VideoIcon.svg"
import Rating from "@mui/material/Rating"
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Link, useParams } from 'react-router-dom';



function Overview() {
  const { chain, courseid } = useParams();
  const course = resources.stellar.mini_courses.find(course => course.id === parseInt(courseid));
  const rewards = course.rewards
  const getStarted = course.chapters.filter(chapter => chapter.difficulty === 'intro');
  const getAdvanced = course.chapters.filter(chapter => chapter.difficulty === 'advanced');

  const reviews = course.reviews
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

    return (
      <div className='w-full h-full flex justify-center'>
      {/* <Header /> */}

        <div className="overview_container my-48 w-[70%] flex justify-center">
          <div className="w-[30%]">
          <div className="border-2 border-[#262626] rounded-2xl relative my-8" style={{background: 'linear-gradient(to bottom, #141414, #0D0D0D)'}}>
                 <div className="rounded-t-xl flex justify-center items-center w-full mx-auto" >
                   <img src={ResourceHubHeroGalaxy} className="border-[#1C1C1C] border-1 rounded-2xl"/> 
                 </div>
                 <div className="py-1 mt-4 px-4">
                   <p className="text-3xl text-white text-left font-bold mb-2">{course.title}</p>
                   <p className="text-md text-[#868686] font-semibold">{course.info}</p>
                 </div>
                 <div className="my-2 px-4">
                    <div className="text-white border-2 border-[#161616] py-1 font-bold flex items-center pl-2 rounded-lg text-md h-[70%]" style={{background: 'linear-gradient(to right, #252525, black)'}}>
                            Start Learning
                        <span className='text-white ml-auto mr-2'>
                            ➚
                        </span>
                    </div>
                </div>
               </div>
               <div className="my-8 border-2 border-[#262626] rounded-2xl relative p-4" style={{background: 'linear-gradient(to bottom, #141414, #0D0D0D)'}}>
                  <div className="text-xl text-[#999999] font-semibold mb-4">Rewards</div>
                  {rewards.map((reward, index) => (
                    <div key={index} className="flex items-center mb-4">
                      <div className="mr-4 text-3xl flex items-center">{reward.icon}</div>
                      <div>
                        <div className="text-xl text-white font-bold">{reward.title}</div>
                        <div className="text-[#999999]">{reward.info}</div>
                      </div>
                    </div>
                  ))}
                </div>
            <div className="my-8 border-2 border-[#262626] rounded-2xl relative p-4" style={{background: 'linear-gradient(to bottom, #141414, #0D0D0D)'}}>
              <div className="text-xl text-[#999999] font-semibold mb-4">Time and Effort</div>
                <div className="flex items-center mb-4">
                  <div>
                  <div className="mr-4 text-3xl flex items-center">🕐</div>
                  </div>
                  <div>
                    <div className="text-xl text-white font-bold">{course.time}</div>
                  </div>
                </div>
                <div className="flex items-center mb-4">
                  <div>
                  <div className="mr-4 text-3xl flex items-center">🐥</div>
                  </div>
                  <div>
                    <div className="text-xl text-white font-bold">{course.effort}</div>
                  </div>
                </div>
            </div>
          </div>
          <div className="mt-8 ml-8 w-[70%]">
            <div className="text-4xl font-bold text-white">
              Course Overview
            </div>
            <div className="mt-3 font-bold text-[#C0C0C0] text-xl">
              {course.overview}
            </div>
            <div className="mt-10">
              <div className="text-[#999999] px-2 font-bold text-lg">
                LET'S GET STARTED
              </div>
              <div className="relative my-4">
                {getStarted.map((chapter, index) => (
                  <Accordion
                    key={index}
                    style={{
                      background: 'linear-gradient(to bottom, #141414, #0D0D0D)',
                      borderBottomWidth: index === getStarted.length - 1 ? '2px' : '0.2px',
                      borderLeftWidth: '2px',
                      borderRightWidth: '2px',
                      borderTopWidth: index === 0 ? '2px' : '0.5px',
                      borderColor: '#262626',
                      borderTopLeftRadius: index === 0 ? '1rem' : '0',
                      borderTopRightRadius: index === 0 ? '1rem' : '0',
                      borderBottomLeftRadius: index === getStarted.length - 1 ? '1rem' : '0',
                      borderBottomRightRadius: index === getStarted.length - 1 ? '1rem' : '0',
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}>
                      <div className="text-[#DEDEDE] py-1 font-bold text-lg">{chapter.title}</div>
                      <div className="ml-4 py-1 mt-auto text-md text-[#626262]">{chapter.articles} Articles - {chapter.videos} Videos</div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-[#080808]" style={{borderRadius: '1rem'}}>
                    {chapter.modules.map((module, index) => (
                      <div className="flex justify-between items-center text-[#DEDEDE] py-2 text-lg font-bold" key={index}>
                        <Link to={`/${chain}/hub/${course.id}/${chapter.id}/${module.id}`} key={index}> 
                          <div>
                            {module.type === 'Document' && (
                              <span className="mr-2"><img src={DocumentIcon} alt="Document Icon" className="inline-block" /></span>
                            )}
                            {module.type === 'Video' && (
                              <span className="mr-2"><img src={VideoIcon} alt="Video Icon" className="inline-block" /></span>
                            )}
                            {module.title}
                          </div>
                        </Link>
                        <div className="text-[#5F5F5F] text-sm">
                          {module.time}
                        </div>
                      </div>
                    ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
            <div className="mt-10">
              <div className="text-[#999999] px-2 font-bold text-lg">
                GETTING ADVANCED
              </div>
              <div className="relative my-4">
                {getAdvanced.map((chapter, index) => (
                  <Accordion
                    key={index}
                    style={{
                      background: 'linear-gradient(to bottom, #141414, #0D0D0D)',
                      borderBottomWidth: index === getStarted.length - 1 ? '2px' : '0.2px',
                      borderLeftWidth: '2px',
                      borderRightWidth: '2px',
                      borderTopWidth: index === 0 ? '2px' : '0.5px',
                      borderColor: '#262626',
                      borderTopLeftRadius: index === 0 ? '1rem' : '0',
                      borderTopRightRadius: index === 0 ? '1rem' : '0',
                      borderBottomLeftRadius: index === getStarted.length - 1 ? '1rem' : '0',
                      borderBottomRightRadius: index === getStarted.length - 1 ? '1rem' : '0',
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}>
                      <div className="text-[#DEDEDE] py-1 font-bold text-lg">{chapter.title}</div>
                      <div className="ml-4 py-1 mt-auto text-md text-[#626262]">{chapter.articles} Articles - {chapter.videos} Videos</div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-[#080808]" style={{borderRadius: '1rem'}}>
                    {chapter.modules.map((module, index) => (
                      <div className="flex justify-between items-center text-[#DEDEDE] py-2 text-lg font-bold" key={index}>
                        <Link to={`/${chain}/hub/${course.id}/${chapter.id}/${module.id}`} key={index}> 
                          <div>
                            {module.type === 'Document' && (
                              <span className="mr-2"><img src={DocumentIcon} alt="Document Icon" className="inline-block" /></span>
                            )}
                            {module.type === 'Video' && (
                              <span className="mr-2"><img src={VideoIcon} alt="Video Icon" className="inline-block" /></span>
                            )}
                            {module.title}
                          </div>
                        </Link>
                        <div className="text-[#5F5F5F] text-sm">
                          {module.time}
                        </div>
                      </div>
                    ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
            <div className="text-4xl text-white mt-12">
              Rated <span className="text-[#F9AB11] font-bold ">{course.rating}</span> by <span className="text-white font-bold ">{course.ratedBy} Devs</span>
            </div>
            <Rating value={course.rating} readOnly className="mt-2" style={{ fontSize: '2rem' , color: "#F9AB11"}} />
            <Slider {...settings}>
            {reviews.map((review, index) => (
              
                 <div key={index} className="h-[400px] mt-8 relative flex items-center">
                 <div className="border-2 border-[#262626] font-bold rounded-t-3xl h-[70%] w-full px-4 py-4 text-xl text-[#FDFDFD] mx-auto my-auto" >
                   {review.comment}
                 </div>
                 <div className="h-[30%] rounded-b-3xl flex" style={{background: 'linear-gradient(to right, #1A1A1A, #0F0F0F)', borderBottomWidth: "2px", borderLeftWidth: "2px", borderRightWidth: "2px", borderColor: "#262626"}}>
                    <div className="w-[20%]">
                      <img src={review.user_image} alt="User" className="h-full w-full object-cover rounded-full" />
                    </div>

                    <div className="w-[80%] flex flex-col justify-center items-start px-4">
                      <div className="text-white font-bold text-xl">{review.user}</div>
                      <div className="text-[#999999]">{review.occupation}</div>
                    </div>
                  </div>
                </div>
            ))}
        </Slider>
          </div>
        </div>
      </div>
    );
  };
  
  export default Overview;