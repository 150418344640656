import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import gigImage1 from "../../assets/images/333.jpg";
import { useSnackbar } from "notistack";
import OBCApi from "../../api/api";
import { useNavigate } from "react-router-dom";
import delete_button from "../../assets/images/delete_button.png";

export default function EventsList({
  renderdomain_tagsWithDynamicLimit,
  openEventModal,
}) {
  const [showLoading, setShowLoading] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const [cardsData, setCardsData] = useState([]);
  const formatDateTime = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleDateString("en-US", options);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return {
      formattedDate,
      formattedTime,
    };
  };
  useEffect(() => {
    setShowLoading(true);
    const api = new OBCApi();
    api
      .getEvents()
      .then((res) => {
        setShowLoading(false);
        console.log(res.data.data);
        const formattedEvents = res.data.data.events.map((data) => {
          const event = data.event;
          const startDate = formatDateTime(event.start);
          const endDate = formatDateTime(event.end);

          return {
            ...event,
            date: startDate.formattedDate,
            time: startDate.formattedTime,
            endDate,
            img: gigImage1,
          };
        });

        setCardsData(formattedEvents);
      })
      .catch((err) => {
        setShowLoading(false);
        showErrorSnack(err);
      });
  }, []);

  const navigate = useNavigate();

  const editEvent = (card) => {
    navigate("/admin/event/edit", { state: card });
  };

  const openDeleteConfirmation = (eventId) => {
    setSelectedEventId(eventId);
    setConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setSelectedEventId("");
    setConfirmationOpen(false);
  };

  const deleteEvent = (eventData) => {
    const api = new OBCApi(localStorage.getItem("adminjwt"));
    console.log(localStorage.getItem("adminjwt"));
    console.log(eventData);

    const data = {
      eventID: eventData.id,
    };

    console.log("deleting");
    api
      .deleteEvent(data)
      .then(() => {
        showSuccessSnack("Event deleted successfully!");
        api
          .getEvents()
          .then((res) => {
            setShowLoading(false);
            console.log(res.data.data);
            const formattedEvents = res.data.data.events.map((data) => {
              const event = data.event;
              const startDate = formatDateTime(event.start);
              const endDate = formatDateTime(event.end);

              return {
                ...event,
                date: startDate.formattedDate,
                time: startDate.formattedTime,
                endDate,
                img: gigImage1,
              };
            });

            setCardsData(formattedEvents);
          })
          .catch((err) => {
            setShowLoading(false);
            showErrorSnack(err);
          });
      })
      .catch((err) => {
        console.log(err);
        showErrorSnack(err.response.data.message);
      });
  };

  return (
    <div className=" orgpage_main_infocontainer_cardscontainer">
      <Dialog
        PaperProps={{
          sx: {
            background: `linear-gradient(to right, #1A1A1A, #0F0F0F)`,
            border: "2px solid #2D2D2D",
            width: "100%",
          },
        }}
        open={isConfirmationOpen}
        onClose={closeDeleteConfirmation}
      >
        <DialogTitle sx={{ color: "white", font: "semibold" }}>
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "white", font: "semibold" }}>
            Are you sure you want to delete this event?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirmation}>Cancel</Button>
          <Button
            onClick={() => {
              deleteEvent({ id: selectedEventId });
              closeDeleteConfirmation();
            }}
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div className=" orgpage_main_infocontainer_cardscontainer_cards">
        {cardsData.map((card, index) => (
          <div
            key={index}
            className="orgpage_main_infocontainer_cardscontainer_cards_card"
          >
            <div className="orgpage_main_infocontainer_cardscontainer_cards_card_top">
              <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left">
                <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_image">
                  <img src={card.img} alt="gig" />
                </div>
                <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details">
                  <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_submission">
                    Submissions Open
                  </div>

                  <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_name">
                    {" "}
                    {card.name}
                  </div>
                  {/* <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_by">
                          by {card.createdBy}
                        </div> */}
                  <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_desc">
                    {" "}
                    {card.description}
                  </div>
                </div>
              </div>
              <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right">
                <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_buttoncontainer">
                  <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_buttoncontainer_button">
                    <img
                      src={delete_button}
                      onClick={() => openDeleteConfirmation(card.id)}
                      alt="Delete"
                    />
                  </div>
                  <div
                    onClick={() => editEvent(card)}
                    className="orgpage_main_infocontainer_cardscontainer_cards_card_right_buttoncontainer_button"
                  >
                    Edit
                  </div>
                  <div
                    onClick={() => {
                      console.log(card);
                      openEventModal(card);
                    }}
                    className="orgpage_main_infocontainer_cardscontainer_cards_card_right_buttoncontainer_button"
                  >
                    Mark Attendance
                  </div>
                </div>

                {/* <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_date">
                    Application ends on {(card.expiryDate)}
                  </div> */}
              </div>
            </div>
            <div>
              <div className="orgpage_main_infocontainer_cardscontainer_cards_card_bottom">
                <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_top">
                  <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_top_type">
                    {card.date}, {card.time}
                  </div>
                  <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_top_amount">{`${card.rewardXp} ZXP`}</div>
                </div>
                {/* <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_tags">
                  {renderdomain_tagsWithDynamicLimit(card.tags, 30)}
                </div> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
