import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import ChainPage from "./pages/ChainPage/ChainPage";
import ResourceHubPage from "./pages/ResourceHubPage/ResourceHubPage";
import GigsPage from "./pages/GigsPage/GigsPage";
import OrganizationPage from "./pages/OrganizationPage/OrganizationPage";
import CreateGigPage from "./pages/CreateQuestPage/CreateGigPage";
import Ecosystems from "./pages/EcosystemPage/Ecosystems";
import ContactUs from "./pages/ContactUs/ContactUs";
import Login from "./pages/Login/Login";
import UserProfileSignup from "./pages/UserProfileSignup/UserProfileSignup";
import UserProfile from "./pages/UserProfile/UserProfile";
import RegisterOrg from "./pages/RegisterOrg/RegisterOrg";
import Auth from "./pages/Auth/authPage";
import { useEffect, useState } from "react";
import OBCApi from "./api/api";
import resources from "./pages/ResourceHubPage/resources.json";
import Overview from "./pages/ResourceHubPage/Overview";
import Tutorial from "./pages/ResourceHubPage/Tutorial";
import { Leaderboard } from "@mui/icons-material";
import LeaderboardPage from "./pages/LeaderboardPage/LeaderboardPage";
import CreateEventPage from "./pages/CreateEventPage/CreateEventPage";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { CHAIN_NAMESPACES, IProvider, WALLET_ADAPTERS } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { WalletConnectModal } from "@walletconnect/modal";

import RPC from "./web3RPC.ts"; // for using web3.js

// Adapters
import {
  WalletConnectV2Adapter,
  getWalletConnectV2Settings,
} from "@web3auth/wallet-connect-v2-adapter";
import { MetamaskAdapter } from "@web3auth/metamask-adapter";
import { useSnackbar } from "notistack";
import Header from "./components/Header/StellarHeader/Header.js";

function App() {
  const [web3auth, setWeb3auth] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [provider, setProvider] = useState(null);
  const [address, setAddress] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  useEffect(() => {
    const init = async () => {
      try {
        const web3auth = new Web3AuthNoModal({
          clientId:
            "BOtu5KU_MzM-wWqqlND-9AAbpi7EzkDzbb4YBJL1vB1tvsAaXWkdu5ChkAelVxisHM4rsVIL2tLF4365f_vgUfE", // Get your Client ID from the Web3Auth Dashboard
          web3AuthNetwork: "sapphire_devnet", // Web3Auth Network
          chainConfig: {
            chainNamespace: "eip155",
            chainId: "0x1",
            rpcTarget: "https://rpc.ankr.com/eth",
            displayName: "Ethereum Mainnet",
            blockExplorer: "https://goerli.etherscan.io",
            ticker: "ETH",
            tickerName: "Ethereum",
          },
        });
        const chainConfig = {
          chainNamespace: "eip155",
          chainId: "0x1",
          rpcTarget: "https://rpc.ankr.com/eth",
          displayName: "Ethereum Mainnet",
          blockExplorer: "https://goerli.etherscan.io",
          ticker: "ETH",
          tickerName: "Ethereum",
        };
        const privateKeyProvider = new EthereumPrivateKeyProvider({
          config: {
            chainConfig,
          },
        });

        const openloginAdapter = new OpenloginAdapter({
          loginSettings: {
            mfaLevel: "none",
          },
          adapterSettings: {
            uxMode: "redirect",
            whiteLabel: {
              appName: "Onboarding.club",
              appUrl: "https://onboarding.club",
              logoLight: "https://i.ibb.co/SNCtLK4/Group-1171279303.png",
              logoDark: "https://i.ibb.co/SNCtLK4/Group-1171279303.png",
              defaultLanguage: "en",
              mode: "dark",
            },
            mfaSettings: {
              deviceShareFactor: {
                enable: true,
                priority: 1,
                mandatory: true,
              },
              backUpShareFactor: {
                enable: true,
                priority: 2,
                mandatory: false,
              },
              socialBackupFactor: {
                enable: true,
                priority: 3,
                mandatory: false,
              },
              passwordFactor: {
                enable: true,
                priority: 4,
                mandatory: false,
              },
            },
          },
          privateKeyProvider,
        });
        web3auth.configureAdapter(openloginAdapter);

        const defaultWcSettings = await getWalletConnectV2Settings(
          "eip155",
          [1],
          "04309ed1007e77d1f119b85205bb779d"
        );
        const walletConnectModal = new WalletConnectModal({
          projectId: "04309ed1007e77d1f119b85205bb779d",
        });
        const walletConnectV2Adapter = new WalletConnectV2Adapter({
          adapterSettings: {
            qrcodeModal: walletConnectModal,
            ...defaultWcSettings.adapterSettings,
          },
          loginSettings: { ...defaultWcSettings.loginSettings },
        });

        web3auth.configureAdapter(walletConnectV2Adapter);

        // adding metamask adapter
        const metamaskAdapter = new MetamaskAdapter({
          clientId:
            "BOtu5KU_MzM-wWqqlND-9AAbpi7EzkDzbb4YBJL1vB1tvsAaXWkdu5ChkAelVxisHM4rsVIL2tLF4365f_vgUfE",
          sessionTime: 3600, // 1 hour in seconds
          web3AuthNetwork: "sapphire_devnet",
          chainConfig: {
            chainNamespace: "eip155",
            chainId: "0x1",
            rpcTarget: "https://rpc.ankr.com/eth", // This is the public RPC we have added, please pass on your own endpoint while creating an app
          },
        });
        metamaskAdapter.setAdapterSettings({
          sessionTime: 86400, // 1 day in seconds
          chainConfig: {
            chainNamespace: "eip155",
            chainId: "0x1",
            rpcTarget: "https://rpc.ankr.com/eth", // This is the public RPC we have added, please pass on your own endpoint while creating an app
          },
          web3AuthNetwork: "sapphire_devnet",
        });

        web3auth.configureAdapter(metamaskAdapter);

        setWeb3auth(web3auth);

        await web3auth.init();
        setProvider(web3auth.provider);
        console.log("hello");
        // if (web3auth.connected && !localStorage.getItem("jwt")){
        //  await web3auth.logout();
        // }
        if (web3auth.connected) {
          console.log(provider);
          const idToken = await web3auth.authenticateUser();
          const api = new OBCApi();

          api
            .loginUser(idToken)
            .then((res) => {
              console.log(res.data.data.token);
              localStorage.setItem("jwt", res.data.data.token);
              setLoggedIn(true);
              // navigate("/");
            })
            .catch((err) => {
              showErrorSnack(err);
            });
          console.log(idToken);
        }
      } catch (error) {
        console.log(error);
        showErrorSnack(error.message);
      }
    };

    init();
  }, []);
  return (
    <>
      <Header
        web3auth={web3auth}
        loggedIn={loggedIn}
        setLoggedIn={setLoggedIn}
      />
      <Routes>
        <Route path="/" element={<ResourceHubPage loggedIn={loggedIn} />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/ecosystems" element={<Ecosystems />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route
          path="/authenticate"
          element={
            <Login
              web3auth={web3auth}
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
            />
          }
        />
        <Route
          path="/:chain/profile-complete"
          element={<UserProfileSignup />}
        />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/leaderboard" element={<LeaderboardPage />} />
        <Route path="/:chain/register-org" element={<RegisterOrg />} />
        <Route
          path="/events"
          element={<ResourceHubPage loggedIn={loggedIn} />}
        />
        <Route path="/quests" element={<GigsPage loggedIn={loggedIn} />} />
        <Route path="/admin/gig/create" element={<CreateGigPage />} />
        <Route path="/admin/gig/edit" element={<CreateGigPage />} />
        <Route path="/admin/event/create" element={<CreateEventPage />} />
        <Route path="/admin/event/edit" element={<CreateEventPage />} />
        <Route path="/admin" element={<OrganizationPage />} />
        <Route path={`/:chain/hub/:courseid`} element={<Overview />} />
        <Route
          path="/:chain/hub/:courseid/:chapterid/:moduleid"
          element={<Tutorial />}
        />
      </Routes>
    </>
  );
}

export default App;
