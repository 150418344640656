import axios from "axios";
import { backendUrl } from "./configs";

class OBCApi {
  jwt;
  base = `${backendUrl}`;
  // chain;
  constructor(jwt) {
    this.jwt = jwt;
  }

  //User const routes

  ////GET

  loginUser(token) {
    const route = `/user/profile/signup`;
    return this.prepPostRequest(route, token);
  }
  loginAdmin(token) {
    const route = `/admin/profile/signup`;
    return this.prepAdminPostRequest(route, token);
  }
  getUserProfile() {
    const route = `/user/profile`;
    return this.prepGetRequest(route, {});
  }
  updateUserProfile(userEntity) {
    const route = `/user/profile`;
    return this.prepPutRequest(route, userEntity);
  }
  userGetUser(username) {
    const route = `/user/${username}`;
    return this.prepGetRequest(route, {});
  }
  userGetUserProfile() {
    const route = `/user`;
    return this.prepGetRequest(route, {});
  }
  ////PUT
  userUpdateUser(userEntity) {
    const route = `/user/`;
    return this.prepPutRequest(route, userEntity);
  }

  //Org const routes

  ////POST
  orgCreateOrg(orgEntity) {
    const route = `/${this.chain}/organisation`;
    return this.prepPostRequest(route, orgEntity);
  }

  ////GET
  orgGetOrg(username) {
    const route = `/${this.chain}/organisation/${username}`;
    return this.prepGetRequest(route, {});
  }

  ////PUT
  orgUpdateOrg(orgEntity) {
    const route = `/${this.chain}/organisation`;
    return this.prepPutRequest(route, orgEntity);
  }

  //Gigs const routes

  ////POST
  gigCreateGig(gigEntity) {
    const route = `/${this.chain}/gigs`;
    return this.prepPostRequest(route, gigEntity);
  }
  createQuest(questData) {
    const route = `/admin/quest/create`;
    return this.prepAdminPostRequest(route, questData);
  }
  editQuest(data) {
    const route = `/admin/quest/${data.id}`;
    return this.prepAdminPutRequest(route, data);
  }
  createEvent(eventData) {
    const route = `/admin/event/create`;
    return this.prepAdminPostRequest(route, eventData);
  }
  editEvent(data) {
    const route = `/admin/event/${data.id}`;
    return this.prepAdminPutRequest(route, data);
  }
  gigDeleteGig(organisation) {
    const route = `/${this.chain}/${organisation}/gigs/delete`;
    return this.prepPostRequest(route, {});
  }

  ////GET
  gigGetOrgGigs(organisation) {
    const route = `/${this.chain}/${organisation}/gigs`;
    return this.prepGetRequest(route, {});
  }
  gigGetGig(id, organisation) {
    const route = `/${this.chain}/${organisation}/gigs/${id}`;
    return this.prepGetRequest(route, {});
  }
  gigGetChainGig() {
    const route = `/${this.chain}/gigs/`;
    return this.prepGetRequest(route, {});
  }
  getQuests() {
    const route = `/user/quest/all`;
    return this.prepGetRequest(route, {});
  }
  getSubmission(data) {
    const route = `/user/quest/submission/${data.questID}`;
    return this.prepGetRequest(route, {});
  }
  getLeaderboard() {
    const route = `/user/profile/leaderboard`;
    return this.prepGetRequest(route, {});
  }
  getEvents() {
    const route = `/user/event/all`;
    return this.prepGetRequest(route, {});
  }
  getEligibleUsersForEvent(data) {
    const route = `/admin/event/${data.id}/eligibleUsers`;
    return this.prepAdminGetRequest(route, {});
  }

  //Gig Applications

  ////PUT

  editsubmitQuest(data) {
    const route = `/user/quest/${data.questID}/update`;
    const answer = { answer: data.answer };
    return this.prepPutRequest(route, answer);
  }

  toggleQuest(data) {
    const route = `/admin/quest/${data.questID}/toggle`;
    return this.prepAdminPutRequest(route, data);
  }

  ////DELETE

  deleteQuest(data) {
    const route = `/admin/quest/${data.questID}`;
    return this.prepAdminDeleteRequest(route, data);
  }

  deleteEvent(data) {
    const route = `/admin/event/${data.eventID}`;
    return this.prepAdminDeleteRequest(route, data);
  }

  ////POST
  submitQuest(data) {
    const route = `/user/quest/${data.questID}/submit`;
    const answer = { answer: data.answer };
    return this.prepPostRequest(route, answer);
  }

  userMarkAttendance(data) {
    const route = `/user/event/${data.eventID}/qr-for-attendance`;
    const answer = { token: data.token };
    return this.prepPostRequest(route, answer);
  }

  manuallyMarkAttendance(data) {
    const route = `/admin/event/${data.eventID}/attendance`;
    return this.prepAdminPostRequest(route, data);
  }

  gigAppCreate(gigAppEntity) {
    const route = `/${this.chain}/gigs/application`;
    return this.prepPostRequest(route, gigAppEntity);
  }
  gigAppUpdateGigApp(gigAppEntity) {
    const route = `/${this.chain}/gigs/update`;
    return this.prepPostRequest(route, gigAppEntity);
  }
  gigAppDeleteGigApp(id) {
    const route = `/${this.chain}/gigs/delete/${id}`;
    return this.prepPostRequest(route, {});
  }
  gigAppSelectWinner(gigApplicationWinnerEntity) {
    const route = `/${this.chain}/gigs/application/select`;
    return this.prepPostRequest(route, gigApplicationWinnerEntity);
  }

  ////GET
  rewardQuestSubmission(id) {
    const route = `/admin/quest/reward`;
    return this.prepAdminPostRequest(route, id);
  }
  getQuestSubmissions(id) {
    const route = `/admin/quest/${id}/submissions`;
    return this.prepAdminGetRequest(route, {});
  }
  getAdminAllUsers() {
    const route = `/admin/user/all`;
    return this.prepAdminGetRequest(route, {});
  }
  getQRAttendance(id) {
    const route = `/admin/event/${id}/qr-for-attendance`;
    return this.prepAdminGetRequest(route, {});
  }
  adminUpdateXP(data) {
    const route = `/admin/user/awardxp`;
    return this.prepPostRequest(route, data);
  }
  adminAddTags(data) {
    const route = `/admin/user/tags`;
    return this.prepPostRequest(route, data);
  }
  gigAppGetGigApp(id) {
    const route = `/${this.chain}/gigs/application/${id}`;
    return this.prepGetRequest(route, {});
  }
  gigAppUserGigApp() {
    const route = `/${this.chain}/gigs/applications`;
    return this.prepGetRequest(route, {});
  }

  prepPostRequest(route, body) {
    return axios.post(`${this.base}${route}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.jwt}`,
      },
    });
  }
  // headers: {
  //   "Content-Type": "application/json",
  //   Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbklkIjoxLCJpYXQiOjE3MDEwMDM3MjgsImV4cCI6MTcwMTYwODUyOH0.z6Aw5HB6mrwWvcKiGjI5KQdUCs5NYAWXcaGsOOPgRAg`,
  // },
  prepGetRequest(route, body) {
    return axios.get(`${this.base}${route}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.jwt}`,
      },
    });
  }
  prepAdminGetRequest(route, body) {
    return axios.get(`${this.base}${route}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.jwt}`,
      },
    });
  }
  prepAdminPostRequest(route, body) {
    return axios.post(`${this.base}${route}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.jwt}`,
      },
    });
  }

  prepAdminDeleteRequest(route, params) {
    return axios.delete(`${this.base}${route}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.jwt}`,
      },
      params: params, 
    });
  }
  

  prepAdminPutRequest(route, body) {
    return axios.put(`${this.base}${route}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.jwt}`,
      },
    });
  }
  prepPutRequest(route, body) {
    return axios.put(`${this.base}${route}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.jwt}`,
      },
    });
  }
}

export default OBCApi;
