import React, { useEffect, useState } from "react";
import "./OrganizationPage.scss";
import Header from "../../components/Header/StellarHeader/Header";
import gigImage from "../../assets/images/gigimage.svg";
import orgicon from "../../assets/images/orgicon.svg";
import zoicon from "../../assets/images/zo.png";
import twitter from "../../assets/images/twitter.svg";
import linked_in from "../../assets/images/linkedin.svg";
import { Link } from "react-router-dom";
import SubmissionsDrawer from "./SubmissionsDrawer";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import OBCApi from "../../api/api";
import { useParams } from "react-router-dom";
import QuestsList from "./QuestsList";
import EventsList from "./EventsList";
import EventAttendanceDrawer from "./EventAttendanceDrawer";
import UsersList from "./UsersList";
import LoginModal from "./LoginModal";

export default function OrganizationPage() {
  const { chain, orgname } = useParams();
  const [data, setData] = useState({
    type: "Design",
    username: "zoworld",
    name: "Zo House BLR",
    domain_tags: ["Community", "Co-Living", "\\Zo Zo/"],
    createdBy: "Sorobix",
    bountyAmount: "2300 XLM",
    about:
      "Each human is a unique vibration. Curating vibes for Zo community.",
    expiryDate: "October 31, 2023",
    createdDate: "August 31, 2023",
    twitter: "BLRxZo",
    linked_in: "keybase",
    followers: "20",
    icon: gigImage,
  });
  const [cardsData, setCardsData] = useState([
    {
      type: "Design",
      name: "App Redesign: Focus on UX & UI",
      tags: ["Figma", "UI/UX", "Product Research"],
      createdBy: "Sorobix",
      bountyAmount: "2300 XLM",
      about:
        "Redesign the IDE for Sorobix to include a project directory import structure and also talk about how it can change the narrative for user onbaording.",
      expiryDate: "October 31, 2023",
      createdDate: "August 31, 2023",
      icon: gigImage,
    }
  ])
  const [activeTab, setActiveTab] = useState("Quests");

  useEffect(() => {
    // setShowLoading(true)

    const adminjwt = localStorage.getItem("adminjwt");
    if(!validJwt(adminjwt)){
      localStorage.removeItem("adminjwt");
    }
    if(!adminjwt && !validJwt(adminjwt)){
      setLoginModalOpen(true);
    }
    const api = new OBCApi(localStorage.getItem("adminjwt"));
    // api.orgGetOrg(orgname).then((res) => {
    //   setShowLoading(false)
    //   console.log(res.data.data)
    //   setData(res.data.data)
    //   const bdaa = res.data.data.gigs
    //   bdaa.forEach(element => {
    //     element.icon = gigImage
    //   });
    //   setCardsData(res.data.data.gigs)
    // }).catch((err) => {
    //   setShowLoading(false)
    //   showErrorSnack(err);
    // })
  }, [])
  const validJwt = (token) => {
    if(!token)
    {
      return false;
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const decodedToken = JSON.parse(jsonPayload);
      let currentDate = new Date();
      let result = false;
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        result = false;
        console.log("Token expired.");
      } else {
        result = true;
        console.log("Valid token");
      }
      return(result);
  };
const calculateDaysAgo = (createdDate) => {
  const currentDate = new Date();
  const postedDate = new Date(createdDate);
  const timeDifference = currentDate - postedDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (daysDifference === 0) {
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    return hoursDifference > 0
      ? `${hoursDifference} ${hoursDifference === 1 ? "hour" : "hours"} ago`
      : "Less than an hour ago";
  }

  return `${daysDifference} ${daysDifference === 1 ? "day" : "days"} ago`;
};
  const renderdomain_tagsWithDynamicLimit = (domain_tags, maxCharCount) => {
    console.log("asdfasdfasdf",domain_tags)
    let currentCharCount = 0;
    let visibledomain_tags = [];
    let hiddendomain_tagsCount = 0;

    for (const tag of domain_tags) {
      if (currentCharCount + tag.length <= maxCharCount) {
        currentCharCount += tag.length;
        visibledomain_tags.push(tag);
      } else {
        hiddendomain_tagsCount = domain_tags.length - visibledomain_tags.length;
        break;
      }
    }
    return (
      <>
        {visibledomain_tags.map((tag, tagIndex) => (
          <span
            key={tagIndex}
            className="orgpage_main_infocontainer_cardscontainer_cards_card_right_tags_tag"
          >
            {tag}
          </span>
        ))}
        {hiddendomain_tagsCount > 0 && (
          <>
            <span className="orgpage_main_infocontainer_cardscontainer_cards_card_right_tags_tag">
              +{hiddendomain_tagsCount}
            </span>
          </>
        )}
      </>
    );
  };
  const [activeQuest, setActiveQuest] = useState("");
  const [activeEvent, setActiveEvent] = useState("");
  const [isQuestModalOpen, setIsQuestModalOpen] = useState(false);
  const [isUserEditModalOpen, setIsUserEditModalOpen] = useState(false);
        const openQuestModal = (card) => {
          setActiveQuest(card);

          console.log(activeQuest);
          setIsQuestModalOpen(true);
        };
        const openEventModal = (card) => {
          setActiveEvent(card);

          console.log(activeQuest);
          setIsEventModalOpen(true);
        };

  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const closeModal = () => {
    setIsQuestModalOpen(false);
  };
  const closeEventModal = () => {
    setIsEventModalOpen(false);
  };
  
  return (
    <>
      {loginModalOpen && (
        <LoginModal
          onClose={() => {
            console.log("Hi");
            setLoginModalOpen(false);
          }}
        />
      )}
      <div
        style={{
          filter: showLoading || loginModalOpen ? "blur(20px)" : "none",
        }}
        className="orgpage"
      >
        {/* <Header /> */}

        <Loader showLoading={showLoading} setShowLoading={setShowLoading} />

        <div className="orgpage_main">
          <div className="orgpage_main_profilecontainer">
            <div className="orgpage_main_profilecontainer_left">
              <div className="orgpage_main_profilecontainer_left_image">
                <img src={zoicon} alt="icon" />
              </div>
              <div className="orgpage_main_profilecontainer_left_details">
                <div className="orgpage_main_profilecontainer_left_details_titlecontainer">
                  <div className="orgpage_main_profilecontainer_left_details_titlecontainer_title">
                    {data.name}
                  </div>
                  <img src={orgicon} alt="org icon" />
                  {/* <div className="orgpage_main_profilecontainer_left_details_titlecontainer_editbtn">
                  Edit{" "}
                </div> */}
                </div>
                <div className="orgpage_main_profilecontainer_left_details_subtitle">
                  {"@" + data.username}{" "}
                </div>
                <div className="orgpage_main_profilecontainer_left_details_text">
                  {data.followers} Followers
                </div>
                <div className="orgpage_main_profilecontainer_left_details_status">
                  Verified
                </div>
              </div>
            </div>
            <div className="orgpage_main_profilecontainer_center">
              <div className="orgpage_main_profilecontainer_center_title">
                About
              </div>
              <div className="orgpage_main_profilecontainer_center_desc">
                {data.about}
              </div>
              <div className="orgpage_main_profilecontainer_center_socialcontainer">
                <a
                  href={`https://twitter.com/${data.twitter}`}
                  className="orgpage_main_profilecontainer_center_socialcontainer_social"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="orgpage_main_profilecontainer_center_socialcontainer_social_image"
                    src={twitter}
                    alt="twitter"
                  />
                  <div className="orgpage_main_profilecontainer_center_socialcontainer_social_text">
                    {" "}
                    {data.twitter}
                  </div>
                </a>
              </div>
            </div>
            <div className="orgpage_main_profilecontainer_right">
              <div className="orgpage_main_profilecontainer_right_title">
                Domains
              </div>
              <div className="orgpage_main_profilecontainer_right_tags">
                {data.domain_tags.map((tag, index) => (
                  <span
                    key={index}
                    className="orgpage_main_profilecontainer_right_tags_tag"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="orgpage_main_infocontainer">
            <div className="orgpage_main_infocontainer_tabscontainer">
              <div className="orgpage_main_infocontainer_tabscontainer_left">
                <div
                  className={`orgpage_main_infocontainer_tabscontainer_tab ${
                    activeTab === "Quests" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("Quests")}
                >
                  Quests
                </div>
                <div
                  className={`orgpage_main_infocontainer_tabscontainer_tab ${
                    activeTab === "Events" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("Events")}
                >
                  Events
                </div>
                <div
                  className={`orgpage_main_infocontainer_tabscontainer_tab ${
                    activeTab === "Users" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("Users")}
                >
                  Users
                </div>
              </div>
              {activeTab !== "Users" && (
                <Link
                  to={
                    activeTab === "Quests"
                      ? "/admin/gig/create"
                      : "/admin/event/create"
                  }
                  className="orgpage_main_infocontainer_tabscontainer_right"
                >
                  <div>+</div>
                  <div>
                    {activeTab === "Quests" ? "New Quest" : "New Event"}
                  </div>
                </Link>
              )}
            </div>
            {activeTab === "Quests" && !loginModalOpen && (
              <QuestsList
                renderdomain_tagsWithDynamicLimit={
                  renderdomain_tagsWithDynamicLimit
                }
                openQuestModal={openQuestModal}
              />
            )}
            {activeTab === "Events" && !loginModalOpen && (
              <EventsList
                renderdomain_tagsWithDynamicLimit={
                  renderdomain_tagsWithDynamicLimit
                }
                openEventModal={openEventModal}
              />
            )}
            {activeTab === "Users" && !loginModalOpen && (
              <UsersList isUserEditModalOpen={isUserEditModalOpen} setIsUserEditModalOpen={setIsUserEditModalOpen} />
            )}
          </div>
        </div>
        {true && (
          <SubmissionsDrawer
            questData={activeQuest}
            isOpen={isQuestModalOpen}
            onClose={closeModal}
          />
        )}
        <EventAttendanceDrawer
          eventData={activeEvent}
          isOpen={isEventModalOpen}
          onClose={closeEventModal}
        />
      </div>
    </>
  );
}
