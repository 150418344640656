import React, { useEffect, useState } from "react";
import Header from "../../components/Header/StellarHeader/Header";
// import "./UsersList.scss";
import { useParams } from "react-router-dom";
import ResourceHubHeroGalaxy from "../../assets/images/hubelement.svg";
import { useNavigate } from "react-router-dom";

import gigImage from "../../assets/images/gigimage.svg";
import gigImage1 from "../../assets/images/222.jpg";
import gigImage2 from "../../assets/images/111.jpg";
import gigImage3 from "../../assets/images/333.jpg";

import { useSnackbar } from "notistack";
import Loader from "../../components/Loader/Loader";
import EventDetailPage from "../EventDetailPage/EventDetailpage";
import OBCApi from "../../api/api";
import EditUserModal from "./EditUserModal";

let contentBg;
let buildOnBg;

const allowedChains = ["stellar", "cosmos", "flow"];

export default function UsersList({
  isUserEditModalOpen,
  setIsUserEditModalOpen,
}) {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const { chain } = useParams();
  const [activeEvent, setActiveEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = (card) => {
    setActiveEvent(card);

    console.log(activeEvent);
    setIsModalOpen(true);
    document.querySelector(".events").classList.add("modal-open");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.querySelector(".events").classList.remove("modal-open");
  };

  const [leaderboardData, setLeaderboardData] = useState([]);
  useEffect(() => {
    setShowLoading(true);
    getUsers();
  }, []);
  const getUsers = () => {
    const api = new OBCApi(localStorage.getItem("adminjwt"));

    api
      .getAdminAllUsers()
      .then((res) => {
        setShowLoading(false);
        console.log(res.data.data);
        const bdaa = res.data.data.users;
        bdaa.forEach((element) => {
          element.icon = gigImage;
        });
        setLeaderboardData(res.data.data.users); //hard coded data for the time being
      })
      .catch((err) => {
        setShowLoading(false);
        showErrorSnack(err);
      });
  };
  const [activeUser, setActiveUser] = useState({});
  const sortedLeaderboard = leaderboardData.sort((a, b) => b.xp - a.xp);

  return (
    <div className="orgpage_main_infocontainer_leaderboardcontainer">
      {sortedLeaderboard.map((user, index) => (
        <>
          {isUserEditModalOpen && (
            <EditUserModal
              user={activeUser}
              onClose={() => {
                console.log("Hi");
                setIsUserEditModalOpen(false);
                getUsers();
              }}
            />
          )}
          <div
            key={index}
            onClick={() => {
              console.log("Hello", user);
              setActiveUser(user);
              setIsUserEditModalOpen(true);
            }}
            className="orgpage_main_infocontainer_leaderboardcontainer_row"
          >
            <div className="orgpage_main_infocontainer_leaderboardcontainer_row_cell index">
              {index + 1}
            </div>
            <div className="orgpage_main_infocontainer_leaderboardcontainer_row_cell avatar">
              {" "}
              <img src={user?.profilePhoto} alt="user avatar" />{" "}
            </div>
            <div className="orgpage_main_infocontainer_leaderboardcontainer_row_cell name">{`${user?.walletAddress.substring(
              0,
              4
            )}...${user?.walletAddress.slice(-4)}`}</div>
            <div className="orgpage_main_infocontainer_leaderboardcontainer_row_cell points">
              {user?.xp}
            </div>
          </div>
        </>
      ))}
    </div>
  );
}
