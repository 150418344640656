import React, { useEffect, useState } from "react";
import "./Modal.scss";
import linkedin from "../../assets/images/linp.svg";
import twitter from "../../assets/images/twtp.svg";
import discord from "../../assets/images/disp.svg";
import telegram from "../../assets/images/telep.svg";
import { Link } from "react-router-dom";
import OBCApi from "../../api/api";
import { useSnackbar } from "notistack";

export default function LoginModal({ onClose }) {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSetValue = (property, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [property]: newValue,
    }));
  };

  const handleSubmit = () => {
    const api = new OBCApi(localStorage.getItem("adminjwt"), "");
    api
      .loginAdmin(formData)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data.token) {
          setIsSubmitted(true);
          localStorage.setItem("adminjwt", res.data.data.token);
          showSuccessSnack("Logged in successfully");
          onClose();
        }
      })
      .catch((err) => {
        setIsSubmitted(true);
        showErrorSnack(err.message);
      });
  };
  return (
    <div className="loginModal">
      <div className="loginModal_content">
        <div className="loginModal_content_header">
          <div className="loginModal_content_header_title">Admin Login</div>
          <div onClick={onClose} className="loginModal_content_header_cross">
            X
          </div>
        </div>
        <div className="loginModal_content_main">
          <div className="loginModal_content_main_container">
            <div className="loginModal_content_main_container_inputcontainer">
              <div className="loginModal_content_main_container_title">
                Username
              </div>
              <input
                className="loginModal_content_main_container_input"
                type="text"
                name="email"
                placeholder="Enter your username"
                value={formData?.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="loginModal_content_main_container_inputcontainer">
              <div className="loginModal_content_main_container_title">
                Password
              </div>
              <input
                className="loginModal_content_main_container_input"
                type="password"
                name="password"
                placeholder="Enter your password"
                value={formData?.password}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="loginModal_content_footer">
          <div
            onClick={handleSubmit}
            className="loginModal_content_footer_button"
          >
            Submit
          </div>
        </div>
      </div>
    </div>
  );
}
