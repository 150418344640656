import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditableMultiInput from "./EditableMultiInput";

function BasicsComponent({ formData, setFormData }) {
      const categoryOptions = [
        "Design",
        "Frontend",
        "Backend",
        "Content Writing",
        "Full Stack",
        "Research",
        "Others",
      ];

  const handleSetValue = (property, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [property]: newValue,
    }));
  };
    const handleCategoryChange = (e) => {
      setFormData((prevData) => ({
        ...prevData,
        category: e.target.value,
      }));
    };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDateChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      start: e,
    }));
  };
  const [expiryDateTime, setExpiryDateTime] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );
  const minExpDate = new Date(new Date().setDate(new Date().getDate() + 1));
  return (
    <div className="basicscomponent">
      <div className="basicscomponent_inputcontainer">
        <div className="basicscomponent_inputcontainer_title">
          Event Title
        </div>
        <input
          type="text"
          name="name"
          className="basicscomponent_inputcontainer_value"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Enter event title"
        />
      </div>

      <div className="basicscomponent_inputcontainer">
        <div className="basicscomponent_inputcontainer_title">
          Event Date
        </div>
        <DatePicker
          name="start"
          selected={formData.start}
          dateFormat="yyyy-MM-dd HH:mm"
          onChange={handleDateChange}
          minDate={minExpDate}
          showTimeSelect
          className="basicscomponent_inputcontainer_value"
        />
      </div>
    </div>
  );
}

export default BasicsComponent;
