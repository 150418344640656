import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditableMultiInput from "./EditableMultiInput";
import ToggleQuestPeople from "../../components/Toggles/ToggleQuestPeople";

function BasicsComponent({ formData, setFormData }) {
      const categoryOptions = [
        "Design",
        "Frontend",
        "Backend",
        "Content Writing",
        "Full Stack",
        "Research",
        "Others",
      ];

  const handleSetValue = (property, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [property]: newValue,
    }));
  };
    const handleCategoryChange = (e) => {
      setFormData((prevData) => ({
        ...prevData,
        category: e.target.value,
      }));
    };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputChangeNumber = (e) => {
    const { name, value } = e.target;
    const parsedValue = parseInt(value, 10);
    const newValue = parsedValue >= 0 ? parsedValue : 0; 
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };
  

  const handleDateChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      expiryDate: e,
    }));
  };
    const [togglePreview, setTogglePreview] = useState(true);

  const [expiryDateTime, setExpiryDateTime] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );
  const minExpDate = new Date(new Date().setDate(new Date().getDate() + 1));
  return (
    <div className="basicscomponent">
      <div className="basicscomponent_inputcontainer">
        <div className="basicscomponent_inputcontainer_title">Quest Name</div>
        <input
          type="text"
          name="name"
          className="basicscomponent_inputcontainer_value"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Enter quest title"
        />
      </div>
      <div className="basicscomponent_inputcontainer">
        <div className="basicscomponent_inputcontainer_title">About the Quest</div>
        <input
          type="text"
          name="about"
          className="basicscomponent_inputcontainer_value"
          value={formData.about}
          onChange={handleInputChange}
          placeholder="Enter some tagline about the quest"
        />
      </div>
      <div className="basicscomponent_inputcontainer">
        <div className="basicscomponent_inputcontainer_title mb-2">
          Quest Type
        </div>
        <div className="basicscomponent_inputcontainer_subtitle mb-2">
          Choose whether you want any number of submissions or limit it to the first X ones, Quest closes automatically if limit is set.
        </div>
        <ToggleQuestPeople
          togglePreview={togglePreview}
          setTogglePreview={setTogglePreview}
        />

        { !togglePreview && <input
          type="number"
          name="limit"
          className="basicscomponent_inputcontainer_value"
          value={formData.limit}
          onChange={handleInputChangeNumber}
          placeholder="Enter number of people"
        /> }
      </div>
      {/* <div className="basicscomponent_inputcontainer">
        <div className="basicscomponent_inputcontainer_title">
          Listing Category
        </div>
        <select
          name="category"
          value={formData.category}
          className="basicscomponent_inputcontainer_value"
          onChange={handleCategoryChange}
        >
          <option value="">Select a category</option>
          {
            categoryOptions.map((option,id) => (
              <option value={option} key={id}>
                {option}
              </option>
            ))}
        </select>
      </div> */}
      {/* <div className="basicscomponent_inputcontainer">
        <div className="basicscomponent_inputcontainer_title">
          Applications Deadline
        </div>
        <DatePicker
          name="expiryDate"
          selected={formData.expiryDate}
          dateFormat="yyyy-MM-dd HH:mm"
          onChange={handleDateChange}
          minDate={minExpDate}
          showTimeSelect
          className="basicscomponent_inputcontainer_value"
        />
      </div> */}
    </div>
  );
}

export default BasicsComponent;
