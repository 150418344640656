import React, { useState } from "react";
import ToggleGigCreate from "../../components/Toggles/ToggleGigCreate";
import deleteicon from "../../assets/images/delete.svg";

function RewardsPage({ formData, setFormData }) {
  const categoryOptions = ["ZXP"];
  const [togglePreview, setTogglePreview] = useState(true);

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, bountyCurrency: value });
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setFormData({ ...formData, rewardXp: value });
  };

  const addReward = () => {
    setFormData((prevData) => ({
      ...prevData,
      bounty_amounts: [...prevData.bounty_amounts, ""],
    }));
  };

  const deleteReward = (index) => {
    if (formData.bounty_amounts.length === 1) {
      return;
    }
    const updatedAmounts = [...formData.bounty_amounts];
    updatedAmounts.splice(index, 1);
    setFormData({ ...formData, bounty_amounts: updatedAmounts });
  };

  return (
    <div className="basicscomponent">
      <div className="basicscomponent_inputcontainer">
        <div className="basicscomponent_inputcontainer_title">
          NFT Reward(POAP)
        </div>
        <select
          name="category"
          value={formData.category}
          className="basicscomponent_inputcontainer_value"
          onChange={handleCategoryChange}
        >
          <option value="">Select the contract</option>
          {categoryOptions.map((option, id) => (
            <option value={option} key={id}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className="basicscomponent_inputcontainer">
        <div className="basicscomponent_inputcontainer_title">
          ZXP Reward(if any)
        </div>
        {/* <select
          name="category"
          value={formData.bountyCurrency}
          className="basicscomponent_rewardcurrency"
          onChange={handleCategoryChange}
        >
          {categoryOptions.map((option, id) => (
            <option value={option} key={id}>
              {option}
            </option>
          ))}
        </select> */}
        <input
          className="basicscomponent_rewardcontainer_input"
          name="rewardXp"
          placeholder="Number of ZXP"
          value={formData.rewardXp}
          onChange={(e) => handleInputChange(e)}
        />
      </div>
    </div>
  );
}

export default RewardsPage;
