import {
  Drawer,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./SubmissionsDrawer.scss";
import twitterc from "../../assets/images/twittercolored.svg";
import linkedinc from "../../assets/images/linkedincolored.svg";
import MDEditor from "@uiw/react-md-editor";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import QRCode from "react-qr-code";
import OBCApi from "../../api/api";

export default function EventAttendanceDrawer({ eventData, isOpen, onClose }) {
  const [viewState, setViewState] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [eligibleUsers, setEligibleUsers] = useState([]);
  const [Leaderboard, setLeaderboard] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");

  useEffect(() => {
    if (isOpen) {
      setShowLoading(true);
      // Fetch eligible users
      getEligibleUsers();
    }
  }, [isOpen]);

  const markAttendance = (selectedUser, eventData) => {
    const api = new OBCApi(localStorage.getItem("adminjwt"));
    console.log(eventData);
    console.log(selectedUser);
    const dataToSend = {
      eventID: eventData.id,
      userId: selectedUser.userId,
    };
    console.log(dataToSend.userId);
    console.log(dataToSend.eventID);

    api
      .manuallyMarkAttendance(dataToSend)
      .then((res) => {
        setShowLoading(false);
        if (res.data.success) {
          showSuccessSnack("Attendance marked successfully");
        } else {
          showErrorSnack("Failed to mark attendance");
        }
      })
      .catch((err) => {
        setShowLoading(false);
        if (
          err.response &&
          err.response.data &&
          err.response.data.message === "User has already marked attendance"
        ) {
          showErrorSnack("User has already marked attendance");
        } else {
          showErrorSnack(err);
        }
      });
  };

  const getEligibleUsers = () => {
    const api = new OBCApi(localStorage.getItem("adminjwt"));
    api
      .getEligibleUsersForEvent(eventData)
      .then((res) => {
        setShowLoading(false);
        if (res.data.success) {
          setEligibleUsers(res.data.data.eligibleUsers);
          // Update leaderboard state with eligible users
          const updatedLeaderboard = res.data.data.eligibleUsers.map(
            (user, index) => ({
              userId: user.id,
              zxp: user.xp,
              name: user.name,
              avatar: user.profilePhoto,
              numberOfQuests: 0, // You might need to modify this based on your data
              addresses: {}, // You might need to modify this based on your data
            })
          );
          setLeaderboard(updatedLeaderboard);
        } else {
          showErrorSnack("Failed to fetch eligible users");
        }
      })
      .catch((err) => {
        setShowLoading(false);
        showErrorSnack(err);
      });
  };

  const gig = {
    type: "Design",
    name: "App Redesign: Focus on UX & UI",
    tags: ["Figma", "UI/UX", "Product Research"],
    bountyAmount: "1300",
    description:
      "Redesign the IDE for Sorobix to include a project directory import structure and also talk about how it can change the narrative for user onbaording.",
    expiryDate: "October 31, 2023",
    createdDate: "August 31, 2023",
  };

  const leaderboard = [
    {
      userId: "9be7d94f-b9fc-4c5b-9362-16dfdf701cdd",
      zxp: 120,
      name: "Lzy Lad",
      avatar:
        "https://cdn.discordapp.com/avatars/407207216790372352/5c7ce8a39931e68817838a4194fc2be7",
      numberOfQuests: 12,
      addresses: {},
    },
    {
      userId: "dee3760d-68e0-4420-ad29-4cbb73750936",
      zxp: 106,
      name: "kunal.zed",
      avatar:
        "https://cdn.discordapp.com/avatars/599269054955520021/4adb92eefc3b2c29e600ac01ca15018d",
      numberOfQuests: 10,
      addresses: {},
    },
    {
      userId: "3e0b9405-72fc-41d3-a775-2033fa179f2b",
      zxp: 105,
      name: "Dexterart.eth",
      avatar:
        "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
      numberOfQuests: 4,
      addresses: {},
    },
    {
      userId: "536768dd-8f4e-407e-b792-98713c8d31f3",
      zxp: 90,
      name: "Helllll.yeahhhh",
      avatar:
        "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
      numberOfQuests: 11,
      addresses: {},
    },
    {
      userId: "6337f596-13f8-41ea-b4d2-21459736f7cc",
      zxp: 70,
      name: "Reet_s",
      avatar:
        "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
      numberOfQuests: 10,
      addresses: {},
    },
    {
      userId: "8974731a-3029-4753-90dd-62800a93153c",
      zxp: 60,
      name: "creodevi.eth",
      avatar:
        "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
      numberOfQuests: 9,
      addresses: {},
    },
    {
      userId: "89161f16-f69b-4e1e-8cc5-f52fa5e0c7ab",
      zxp: 60,
      name: "ripfarhan",
      avatar:
        "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
      numberOfQuests: 9,
      addresses: {},
    },
    {
      userId: "8a98cdbe-8cd6-4100-8f90-a3b4c074e72c",
      zxp: 60,
      name: "lyght.eth",
      avatar:
        "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
      numberOfQuests: 4,
      addresses: {},
    },
    {
      userId: "c71c0214-b6f8-4843-8e2f-1e4aff6b4f1d",
      zxp: 40,
      name: "Wufm",
      avatar:
        "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
      numberOfQuests: 4,
      addresses: {},
    },
    {
      userId: "329b2be8-eca0-4a7f-a459-325912659469",
      zxp: 30,
      name: "Zordieee",
      avatar:
        "https://cdn.discordapp.com/avatars/739461334621618277/705f596b148ba9f6fc20415664b13326",
      numberOfQuests: 3,
      addresses: {},
    },
    {
      userId: "3192e9e5-17c3-491b-b02a-e9a07b0d972f",
      zxp: 30,
      name: "svrnjxn",
      avatar:
        "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
      numberOfQuests: 3,
      addresses: {},
    },
    {
      userId: "b8504c8e-e315-46cd-a81e-1358c6eff609",
      zxp: 30,
      name: "Mirzadmakhdm.eth",
      avatar:
        "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/b8504c8e-e315-46cd-a81e-1358c6eff609-logo.png",
      numberOfQuests: 3,
      addresses: {},
    },
    {
      userId: "9721dd47-25ea-419f-8577-6636534a099c",
      zxp: 30,
      name: "nfteawala",
      avatar:
        "https://cdn.discordapp.com/avatars/913540828650160168/168d961022cee78caec8be4007fb7965",
      numberOfQuests: 3,
      addresses: {},
    },
    {
      userId: "38e9c8eb-f7a1-4739-a227-25ce8d92f032",
      zxp: 20,
      name: "bhand.eth",
      avatar:
        "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/38e9c8eb-f7a1-4739-a227-25ce8d92f032-t1kv828x_400x400.png",
      numberOfQuests: 2,
      addresses: {},
    },
  ];
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const [QRData, setQRData] = useState({ token: "" });
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  useEffect(() => {
    if (isOpen) {
      setShowLoading(true);
      getQR();
    }
  }, [isOpen]);

  const getQR = () => {
    const api = new OBCApi(localStorage.getItem("adminjwt"));
    console.log("Hello");
    api
      .getQRAttendance(eventData.id)
      .then((res) => {
        setShowLoading(false);
        console.log(res.data.data);

        setQRData(res.data.data);
      })
      .catch((err) => {
        setShowLoading(false);
        showErrorSnack(err);
      });
  };
  return (
    <Drawer anchor={"bottom"} open={isOpen} className="submissionsdrawer">
      <div
        style={{ filter: showLoading ? "blur(20px)" : "none" }}
        className="submissionsdrawer_width"
      >
        <Loader showLoading={showLoading} setShowLoading={setShowLoading} />
        <Dialog
          PaperProps={{
            sx: {
              background: `linear-gradient(to right, #1A1A1A, #0F0F0F)`,
              border: "2px solid #2D2D2D",
              width: "100%",
            },
          }}
          open={isDialogOpen}
          onClose={() => setDialogOpen(false)}
        >
          <DialogTitle sx={{ color: "white", font: "semibold" }}>
            Take Manual Attendance for {selectedUser.name}?
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
                markAttendance(selectedUser, eventData);
              }}
            >
              Yes
            </Button>

            <Button
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
        <div className="submissionsdrawer_closecontainer">
          <button
            className="submissionsdrawer_closecontainer_closebutton"
            onClick={() => {
              onClose();
            }}
          >
            X
          </button>
        </div>
        {viewState === 0 ? (
          <div className="submissionsdrawer_all">
            <div className="submissionsdrawer_all_detailscontainer">
              <div className="submissionsdrawer_all_detailscontainer_top">
                <div className="submissionsdrawer_all_detailscontainer_top_left">
                  <div className="submissionsdrawer_all_detailscontainer_top_left_type">
                    {gig.type}
                  </div>
                  <div className="submissionsdrawer_all_detailscontainer_top_left_amount">{`${eventData.rewardXp} ZXP`}</div>
                </div>
                {/* <div className="submissionsdrawer_all_detailscontainer_top_right">
                  Review and Post Results
                </div> */}
              </div>
              <div className="submissionsdrawer_all_detailscontainer_name">
                {eventData?.name}
              </div>
              <div className="submissionsdrawer_all_detailscontainer_submissions">
                <span>{eligibleUsers.length}</span> Attendees
              </div>
            </div>
            <div className="submissionsdrawer_particular">
              {/* <div
                onClick={() => {
                  setViewState(0);
                }}
                className="submissionsdrawer_particular_subtext"
              >
                Back to Submissions
              </div> */}
              <div className="flex flex-col xl:flex-row gap-8 h-full w-full">
                <div className="submissionsdrawer_particular_left_outer_outer">
                  <div className="submissionsdrawer_particular_left_outer">
                    <div className="submissionsdrawer_particular_left justify-center items-center">
                      <div className="submissionsdrawer_particular_left_qr">
                        <QRCode size={220} value={JSON.stringify(QRData)} />{" "}
                      </div>
                      <div className="submissionsdrawer_particular_left_textcontainer">
                        <div className="submissionsdrawer_particular_left_textcontainer_text">
                          Scan the QR to mark your attendance
                        </div>{" "}
                      </div>
                      <div className="submissionsdrawer_particular_left_textcontainer">
                        <div
                          onClick={getQR}
                          className="submissionsdrawer_particular_left_textcontainer_button"
                        >
                          Refresh QR
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submissionsdrawer_particular_right_outer_outer">
                  <div className="submissionsdrawer_particular_right_outer">
                    <div className="submissionsdrawer_particular_right">
                      <div className="submissionsdrawer_particular_right_title">
                        Users
                      </div>

                      <div className="submissionsdrawer_particular_right_scrollcontainer">
                        <div className="submissionsdrawer_particular_right_scrollcontainer_leaderboardcontainer">
                          {Leaderboard.map((user, index) => (
                            <>
                              <div
                                key={index}
                                className="submissionsdrawer_particular_right_scrollcontainer_leaderboardcontainer_row"
                              >
                                <div className="submissionsdrawer_particular_right_scrollcontainer_leaderboardcontainer_row_cell index">
                                  {index + 1}
                                </div>
                                <div className="submissionsdrawer_particular_right_scrollcontainer_leaderboardcontainer_row_cell avatar">
                                  {" "}
                                  <img
                                    src={user.avatar}
                                    alt="user avatar"
                                  />{" "}
                                </div>
                                <div
                                  onClick={() => {
                                    setSelectedUser(user);
                                    setDialogOpen(true);
                                  }}
                                  className="submissionsdrawer_particular_right_scrollcontainer_leaderboardcontainer_row_cell name"
                                >
                                  {user.name}
                                </div>
                                <div className="submissionsdrawer_particular_right_scrollcontainer_leaderboardcontainer_row_cell points">
                                  {user.zxp}
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Drawer>
  );
}
