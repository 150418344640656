import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useSnackbar } from "notistack";
import Api from "../../utils/api";
import OBCApi from "../../api/api";

export default function QRScanModal({
  showScanner,
  setShowScanner,
  eventID,
}) {
  const [data, setData] = useState("No result");
  const api = new Api(localStorage.getItem("jwt"));
  console.log(eventID);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };
  const handleData = async (payload) => {
    console.log("p",payload.token);
    if (payload.token) {
      console.log("hi");
      console.log(payload);
      const api = new OBCApi(localStorage.getItem("jwt"));
      const eventSecret = {
        eventID,
        token: payload.token,
      };
      api
        .userMarkAttendance(eventSecret)
        .then((res) => {
          console.log(res);
          showSuccessSnack("Attendance marked succesfully!");
          // setViewState(2);
        })
        .catch((err) => {
          showErrorSnack(err);
        });
    }
  };
  return (
    <Modal
      open={showScanner}
      aria-labelledby="loader"
      aria-describedby="loading"
      className="align-middle justify-center items-center outline-none justify-items-center flex h-screen"
    >
      <div className="outline-none h-80 w-80 flex flex-col justify-center items-center">
        {/* <QrReader
          scanDelay={5000}
          onResult={(result, error) => {
            if (!!result) {
              setData(result?.text);
              console.log(result?.text);
              showSuccessSnack(result?.text);
            }
            if (!!error) {
              console.info(error);
            }
          }}
          style={{ width: "100%" }}
        /> */}
        <BarcodeScannerComponent
          width={800}
          height={1800}
          delay={3000}
          onUpdate={(err, result) => {
            if (result) {
              showSuccessSnack("Scan Successful");
              console.log(result);
              handleData(JSON.parse(result?.text));
              setShowScanner(false);
            } else showErrorSnack("Sorry scanning error");
          }}
        />
        <div
          className="font-bold text-xl text-white"
          onClick={() => {
            setShowScanner(false);
          }}
        >
          Close
        </div>
      </div>
    </Modal>
  );
}
