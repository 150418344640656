import { useParams, Link, useLocation } from 'react-router-dom';
import resources from './resources.json';
import Header from "../../components/Header/StellarHeader/Header";
import ResourceHubHeroGalaxy from "../../assets/images/hubelement.svg";
import React, {useEffect, useState, useRef} from "react";
import CodeMirror from '@uiw/react-codemirror';
import { createTheme } from '@uiw/codemirror-themes';
import { rust } from '@codemirror/lang-rust';
import { tags as t } from '@lezer/highlight';
import filledCircle from "../../assets/images/filled_circle.svg";
import emptyCircle from "../../assets/images/empty_circle.svg";
import greenCircleNotBottom from "../../assets/images/green_circle_not_bottom.svg";
import filledCircleNotBottom from "../../assets/images/filled_circle_not_bottom.svg";
import emptyCircleNotBottom from "../../assets/images/empty_circle_not_bottom.svg";

const myTheme = createTheme({
    theme: 'light',
    settings: {
      background: '#011627',
      foreground: '#75baff',
      caret: '#5d00ff',
      selection: '#036dd626',
      selectionMatch: '#036dd626',
      lineHighlight: '#8a91991a',
      gutterBackground: '#fff',
      gutterForeground: '#8a919966',
      
    },
    styles: [
      { tag: t.comment, color: '#637777' },
      { tag: t.variableName, color: '#FFCB8B' },
      { tag: [t.string, t.special(t.brace)], color: '#C792E9' },
      { tag: t.number, color: '#F78C6C' },
      { tag: t.bool, color: '#F78C6C' },
      { tag: t.null, color: '#F78C6C' },
      { tag: t.keyword, color: '#7FDBCA' }, 
      { tag: t.operator, color: '#75baff' }, 
      { tag: t.definition(t.typeName), color: '#FFCB8B' }, 
      { tag: t.className, color: '#FFCB8B' },
      { tag: t.typeName, color: '#7FDBCA' },
      { tag: t.angleBracket, color: '#C792E9' },
      { tag: t.tagName, color: '#5c6166' },
      { tag: t.attributeName, color: '#ACDB67' },
    ],
  });
  const extensions = [rust()];

function Tutorial() {
  const { chain, courseid, chapterid, moduleid } = useParams();
  const course = resources.stellar.mini_courses.find(course => course.id === parseInt(courseid));
  const selectedChapter = course.chapters.find(chapter => chapter.id === parseInt(chapterid));
  const selectedModule = selectedChapter.modules.find(module => module.id === parseInt(moduleid));
  const rewards = course.rewards
  const moduleIndex = selectedChapter.modules.findIndex(module => module.id === parseInt(moduleid));
  const nextIndex = (moduleIndex + 1) % selectedChapter.modules.length;
  const prevIndex = (moduleIndex - 1 + selectedChapter.modules.length) % selectedChapter.modules.length;
  const isFirstModule = moduleIndex === 0;
  const isLastModule = moduleIndex === selectedChapter.modules.length - 1;
  const chapters = course ? course.chapters : [];
  const chapterIndex = chapters.findIndex(chapter => chapter.id === parseInt(chapterid));
  const nextChapter = chapters[(chapterIndex + 1) % chapters.length];
  const isFirstChapter = chapterIndex === 0;
  const isLastChapter = chapterIndex === chapters.length - 1;

  const completedModulesCount = moduleIndex + 1;
  const totalModulesCount = selectedChapter.modules.length;
  const nextModuleId = isLastModule ? nextChapter.modules[0].id : selectedChapter.modules[nextIndex].id;
  const progressPercentage = (completedModulesCount / totalModulesCount) * 100;
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [maxScrollPercentage, setMaxScrollPercentage] = useState(0);
  const [tabSwitchCount, setTabSwitchCount] = useState(0);
  const [milliseconds, setMilliseconds] = useState(0);
  const location = useLocation()

  useEffect(() => {
    const interval = setInterval(() => {
      setMilliseconds((prevMilliseconds) => prevMilliseconds + 1000);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formatTime = (milliseconds) => {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setTabSwitchCount((prevCount) => prevCount + 1);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

    useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollY = window.scrollY;
  
      const scrollPercent = (scrollY / (documentHeight - windowHeight)) * 100;
      setScrollPercentage(scrollPercent);

      if (scrollPercent > maxScrollPercentage) {
        setMaxScrollPercentage(scrollPercent);
      }
    };
  
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [maxScrollPercentage]);
  
  useEffect(() => {
    console.log("Scroll percentage:", maxScrollPercentage)
    console.log("Time spent on this page:", formatTime(milliseconds))
    console.log("Number of Tab Switches:", tabSwitchCount)
    setMilliseconds(0);
    setMaxScrollPercentage(0);
    setTabSwitchCount(0);
  }, [location]); 


  return (
    <div className='w-full h-full flex justify-center'>
        {/* <Header /> */}

        <div className="overview_container my-48 w-[70%] flex justify-center">
          <div className="w-[30%]">
          <div className="border-2 border-[#262626] rounded-2xl relative my-8" style={{background: 'linear-gradient(to bottom, #141414, #0D0D0D)'}}>
                 <div className="rounded-t-xl flex justify-center items-center w-full mx-auto" >
                   <img src={ResourceHubHeroGalaxy} className="border-[#1C1C1C] border-1 rounded-2xl"/> 
                 </div>
                 <div className="py-1 mt-4 px-4 mb-4">
                   <p className="text-3xl text-white text-left font-bold mb-2">{course.title}</p>
                   <p className="text-md text-[#868686] font-semibold">{course.info}</p>
                 </div>
               </div>
               <div className="my-8 border-2 border-[#262626] rounded-2xl relative p-4" style={{background: 'linear-gradient(to bottom, #141414, #0D0D0D)'}}>
              <div className="text-xl text-[#999999] font-semibold mb-4">Lessons</div>
              {selectedChapter.modules.map((module, index) => (
  <div key={index} className="flex">
    {index < moduleIndex ? (
      <img src={greenCircleNotBottom} alt="Previous Module" className="w-14 h-14 mr-2" />
    ) : index === moduleIndex ? (
      index === selectedChapter.modules.length - 1 ? (
        <img src={filledCircle} alt="Current Module" className="w-14 h-6 mr-2 overflow-y-hidden" />
      ) : (
        <img src={filledCircleNotBottom} alt="Current Module" className="w-14 h-14 mr-2" />
      )
    ) : index > moduleIndex ? (
      index === selectedChapter.modules.length - 1 ? (
        <img src={emptyCircle} alt="Upcoming Module" className="w-14 h-6 mr-2 overflow-y-hidden" />
      ) : (
        <img src={emptyCircleNotBottom} alt="Upcoming Module" className="w-14 h-14 mr-2" />
      )
    ) : null}
    <span className={`${index < moduleIndex ? "text-[#2EDA87]" : module.id === parseInt(moduleid) ? "text-white" : "text-[#999999]"} pl-3 text-xl font-bold`}>
      {module.title}
    </span>
  </div>
))}
            </div>
               <div className="my-8 border-2 border-[#262626] rounded-2xl relative p-4" style={{background: 'linear-gradient(to bottom, #141414, #0D0D0D)'}}>
                  <div className="text-xl text-[#999999] font-semibold mb-4">Rewards</div>
                  {rewards.map((reward, index) => (
                    <div key={index} className="flex items-center mb-4">
                      <div className="mr-4 text-3xl flex items-center">{reward.icon}</div>
                      <div>
                        <div className="text-xl text-white font-bold">{reward.title}</div>
                        <div className="text-[#999999]">{reward.info}</div>
                      </div>
                    </div>
                  ))}
                </div>
          </div>
          <div className="mt-8 ml-8 w-[70%]">
            <div className="bg-[#212121] border-2 border-[#3E3E3E] h-2 rounded-lg mb-4">
                <div
                    className="bg-white h-full rounded-lg"
                    style={{ width: `${progressPercentage}%` }}
                ></div>
            </div>
            {!isFirstModule && (
  <Link
    to={`/${chain}/hub/${courseid}/${chapterid}/${selectedChapter.modules[prevIndex].id}`}
    className="text-sm text-[#838383] mr-2 mt-2 mb-2"
  >
    &lt; Previous
  </Link>
)}
{isFirstModule && !isFirstChapter && (
  <Link
    to={`/${chain}/hub/${courseid}/${chapters[chapterIndex - 1]?.id}/${chapters[chapterIndex - 1]?.modules[chapters[chapterIndex - 1]?.modules.length - 1]?.id}`}
    className="text-sm text-[#838383] mr-2 mt-2 mb-2"
  >
    &lt; Previous
  </Link>
)}
        {!isLastModule && (
          <Link
            to={`/${chain}/hub/${courseid}/${chapterid}/${selectedChapter.modules[nextIndex].id}`}
            className="text-sm text-[#838383] mt-2 mb-2"
          >
            Next &gt;
          </Link>
        )}
        {isLastModule && !isLastChapter && (
          <Link
            to={`/${chain}/hub/${courseid}/${nextChapter.id}/${nextModuleId}`}
            className="text-sm text-[#838383] mt-2 mb-2"
          >
            Next &gt;
          </Link>
        )}
          <div className="text-sm text-[#838383] mt-4 mb-4"><Link to={`/${chain}/hub`}>Back to Courses</Link></div>
            <div className="text-5xl text-white font-bold mt-4 mb-4">{selectedModule.title}</div>
            <p className="text-sm mt-4 mb-12">
                <span className="text-[#838383]">🕓 Completion time:</span>
                <span className="text-white">{` ${selectedModule.time}`}</span>
            </p>

            <div style={{ borderRadius: '1rem', overflow: 'hidden' }}>
              {(selectedModule.code !== "") && (
      <CodeMirror
        value={selectedModule.code}
        theme={myTheme}
        extensions={extensions}
      basicSetup={{
           lineNumbers: false,
           foldGutter: false
          }}
      readOnly = {true}
      options={{
        autoCursorHeight: true,
      }}
      />)}
    </div>
            <p className="text-xl text-white mt-4 mb-4">{selectedModule.content}</p>
          </div>
        </div>
    </div>
  );
}

export default Tutorial;
