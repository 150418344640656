import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import SnackbarCloseButton from "./SnackbarCloseButton";
// import "@rainbow-me/rainbowkit/styles.css";
// import {
//   getDefaultWallets,
//   RainbowKitProvider,
//   darkTheme,
// } from "@rainbow-me/rainbowkit";
// import { configureChains, createConfig, WagmiConfig } from "wagmi";
// import {
//   mainnet,
//   polygon,
//   optimism,
//   arbitrum,
//   base,
//   zora,
//   goerli,
// } from "wagmi/chains";
// import { publicProvider } from "wagmi/providers/public";
// const { chains, publicClient, webSocketPublicClient } = configureChains(
//   [mainnet, polygon, optimism, arbitrum, base, zora],
//   [publicProvider()]
// );

// const { connectors } = getDefaultWallets({
//   appName: "RainbowKit demo",
//   projectId: "YOUR_PROJECT_ID",
//   chains,
// });

// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors,
//   publicClient,
//   webSocketPublicClient,
// });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <WagmiConfig config={wagmiConfig}>
  //   <RainbowKitProvider theme={darkTheme()} chains={chains} modalSize="compact">
  <SnackbarProvider
    action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    maxSnack={2}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </SnackbarProvider>
  //   </RainbowKitProvider>
  // </WagmiConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
