import React, { useEffect, useState } from "react";
import Header from "../../components/Header/StellarHeader/Header";
import "./ResourceHubPage.scss";
import { useParams } from "react-router-dom";
import resources from "./resources.json";
import ResourceHubHeroGalaxy from "../../assets/images/hubelement.svg";
import { useNavigate } from "react-router-dom";

import gigImage from "../../assets/images/gigimage.svg";
import gigImage1 from "../../assets/images/222.jpg";
import gigImage2 from "../../assets/images/111.jpg";
import gigImage3 from "../../assets/images/333.jpg";

import { useSnackbar } from "notistack";
import Loader from "../../components/Loader/Loader";
import EventDetailPage from "../EventDetailPage/EventDetailpage";
import OBCApi from "../../api/api";

let contentBg;
let buildOnBg;

const allowedChains = ["stellar", "cosmos", "flow"];

export default function ResourceHubPage({loggedIn}) {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);
  const [cards, setCards] = useState([
    {
      title: "Morning Zo Zo",
      subtitle: "Have a spectacular sunset shot while at the top!",
      time: "08:00 PM",
      date: "25th November, 2023",
      img: gigImage1,
    },
    {
      title: "Morning Zo Zo",
      subtitle: "Have a spectacular sunset shot while at the top!",
      time: "08:00 PM",
      date: "26th November, 2023",
      img: gigImage,
    },
    {
      title: "Morning Zo Zo",
      subtitle: "Have a spectacular sunset shot while at the top!",
      time: "08:00 PM",
      date: "24th November, 2023",
      img: gigImage3,
    },
    {
      title: "Morning Zo Zo",
      subtitle: "Have a spectacular sunset shot while at the top!",
      time: "08:00 PM",
      date: "24th November, 2023",
      img: gigImage2,
    },
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const { chain } = useParams();
  // useEffect(() => {
  //   console.log(chain);
  //   if (!allowedChains.includes(chain)) {
  //     navigate("/ecosystems");
  //   }
  // }, [chain, navigate]);
  const [activeEvent, setActiveEvent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
      const openModal = (card) => {
        setActiveEvent(card);

        console.log(activeEvent);
        setIsModalOpen(true);
        document.querySelector(".events").classList.add("modal-open");
      };

      const closeModal = () => {
        setIsModalOpen(false);
        document.querySelector(".events").classList.remove("modal-open");
      };
    const groupedCards = cards.reduce((acc, card) => {
      const date = card.date;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(card);
      return acc;
    }, {});
  const sortedDates = Object.keys(groupedCards).sort((a, b) => {
    // Custom function to parse and compare date strings
    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split(" ");
      const monthIndex = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ].indexOf(month);
      return new Date(year, monthIndex, parseInt(day), 0, 0, 0, 0);
    };
    return parseDate(a) - parseDate(b);
  });
    const formatDateTime = (dateTimeString) => {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      const date = new Date(dateTimeString);
      const formattedDate = date.toLocaleDateString("en-US", options);
      const formattedTime = date.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

      return {
        formattedDate,
        formattedTime,
      };
    };
  const chainResources = resources[chain] || resources["others"];
  useEffect(() => {
    setShowLoading(true);
    const api = new OBCApi();
    api
      .getEvents()
      .then((res) => {
        setShowLoading(false);
        console.log(res.data.data);
        const formattedEvents = res.data.data.events.map((data) => {
          const event = data.event;
          const startDate = formatDateTime(event.start);
          const endDate = formatDateTime(event.end);

          return {
            ...event,
            date: startDate.formattedDate,
            time: startDate.formattedTime,
            endDate,
            img: gigImage1,
          };
        });

        setCards(formattedEvents);
      })
      .catch((err) => {
        setShowLoading(false);
        showErrorSnack(err);
      });
  }, []);
  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="events"
    >
      {/* <Header /> */}

      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />
      <div className="events_banner">
        <div className="events_banner_left">
          <div className="events_banner_left_title">/events</div>
          <div className="events_banner_left_subtitle">{`\\mani-fest/`}</div>
        </div>
        <div className="events_banner_right">
          <img src={ResourceHubHeroGalaxy} alt="gig" />
        </div>
      </div>
      <div className="events_main">
        <div className="events_main_title">Artist Residency</div>
        <div className="events_main_subtitle">
          Learn from tutorials and guides, join relevant communities and
          kickstart your{" "}
          <span className="events_main_subtitle_span">{chain}</span> journey.
        </div>
        <div className="events_main_cardscontainer">
          {sortedDates.map((date) => (
            <React.Fragment key={date}>
              <div className="events_main_cardscontainer_date">{date}</div>
              {groupedCards[date]
                .sort((a, b) => {
                  return (
                    new Date(`2000-01-01 ${a.time}`) -
                    new Date(`2000-01-01 ${b.time}`)
                  );
                })
                .map((card, index) => (
                  <>
                    <div
                      key={index}
                      className="events_main_cardscontainer_card"
                      onClick={() => {
                        if(loggedIn)
                        openModal(card)
                      else
                      showErrorSnack("Login to access the event!")
                    }}
                    >
                      {" "}
                      <div className="events_main_cardscontainer_card_left">
                        <img src={card?.img} alt="gig" />
                        <div className="events_main_cardscontainer_card_left_details">
                          <div className="events_main_cardscontainer_card_left_details_title">
                            {card?.name}
                          </div>
                          <div className="events_main_cardscontainer_card_left_details_subtitle">
                            {card?.description}
                          </div>
                        </div>
                      </div>
                      <div className="events_main_cardscontainer_card_right">
                        {card.time}
                      </div>
                    </div>
                  </>
                ))}
            </React.Fragment>
          ))}
        </div>
      </div>
      <EventDetailPage
        isOpen={isModalOpen}
        onClose={closeModal}
        eventData={activeEvent}
      />
    </div>
  );
}
