import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import MDEditor from "@uiw/react-md-editor";
import ToggleGigCreate from "../../components/Toggles/ToggleGigCreate";
import deleteicon from "../../assets/images/delete.svg";

function QuestionsComponent({ formData, setFormData }) {
  const categoryOptions = ["Textbox", "Markdown Input"];
  const [mdValue, setMdValue] = React.useState("**Hello world!!!**");
  const [togglePreview, setTogglePreview] = useState(true);
  const [submissionType, setSubmissionType] = useState("General Form");

  const handleCategoryChange = (e, index) => {
    const { value } = e.target;
    const updatedQuestions = [...formData.questions];
    updatedQuestions[index].formatting = value;
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedQuestions = [...formData.questions];
    updatedQuestions[index][name] = value;
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const addQuestion = () => {
    const newQuestion = {
      formatting: "Textbox",
      title: "",
      description: "",
    };
    setFormData((prevData) => ({
      ...prevData,
      questions: [...prevData.questions, newQuestion],
    }));
  };
  const deleteQuestion = (index) => {
    const updatedQuestions = [...formData.questions];
    updatedQuestions.splice(index, 1);
    setFormData({ ...formData, questions: updatedQuestions });
  };
  const handleButtonClick = (buttonName) => {
    setSubmissionType(buttonName);
  };

  return (
    <div className="basicscomponent">
      <div className="basicscomponent_buttoncontainer">
        <div
          className={`basicscomponent_buttoncontainer_button ${
            submissionType === "General Form" ? "active" : ""
          }`}
          onClick={() => {
            setFormData({ ...formData, type: "general-form" });

            handleButtonClick("General Form");
          }}
        >
          General Form
        </div>
        <div
          className={`basicscomponent_buttoncontainer_button ${
            submissionType === "Twitter" ? "active" : ""
          }`}
          onClick={() => {
            setFormData({ ...formData, type: "general-form" });
            handleButtonClick("Twitter");
          }}
        >
          Twitter
        </div>
      </div>
      {submissionType === "General Form" && (
        <>
          <ToggleGigCreate
            togglePreview={togglePreview}
            setTogglePreview={setTogglePreview}
          />
          {togglePreview ? (
            <>
              {formData.questions.map((question, index) => (
                <div className="basicscomponent_questioncontainer" key={index}>
                  <div className="basicscomponent_questioncontainer_top">
                    <div className="basicscomponent_questioncontainer_top_title">
                      Question {index + 1}
                    </div>
                    <div className="basicscomponent_questioncontainer_top_right">
                      <select
                        name="formatting"
                        value={question.formatting}
                        className="basicscomponent_questioncontainer_top_right_value"
                        onChange={(e) => handleCategoryChange(e, index)}
                      >
                        {categoryOptions.map((option, id) => (
                          <option value={option} key={id}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <img
                        src={deleteicon}
                        alt="delete icon"
                        onClick={() => deleteQuestion(index)}
                      />{" "}
                    </div>
                  </div>
                  <input
                    className="basicscomponent_questioncontainer_inputtitle"
                    name="title"
                    placeholder="Title"
                    value={question.title}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                  <input
                    className="basicscomponent_questioncontainer_inputsubtitle"
                    name="description"
                    placeholder="Subtitle"
                    value={question.description}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </div>
              ))}
              <div
                className="basicscomponent_addquestionbutton"
                onClick={addQuestion}
              >
                Add Question
              </div>
            </>
          ) : (
            <>
              {formData.questions.map((question, index) => (
                <div className="basicscomponent_inputcontainer" key={index}>
                  {/* <div className="basicscomponent_inputcontainer_title">
                Question {index + 1}
              </div> */}
                  <div className="basicscomponent_inputcontainer_questiontitle">
                    {question.title}
                  </div>
                  <div className="basicscomponent_inputcontainer_questiondescription">
                    {question.description}
                  </div>
                  {question.formatting === "Textbox" ? (
                    <textarea
                      type="text"
                      name="description"
                      className="basicscomponent_inputcontainer_value"
                      placeholder="Enter your answer"
                      readOnly
                    />
                  ) : (
                    <MDEditor
                      style={{ backgroundColor: "#181818" }}
                      className="mt-2"
                      value={"Enter your answer below"}
                      name="details"
                      readOnly
                    />
                  )}
                </div>
              ))}
            </>
          )}
        </>
      )}
      {submissionType === "Twitter" && (
        <>
          <ToggleGigCreate
            togglePreview={togglePreview}
            setTogglePreview={setTogglePreview}
          />
        </>
      )}
    </div>
  );
}

export default QuestionsComponent;
