import React, { useState } from "react";
import "./EventDetailPage.scss";
import Drawer from "@mui/material/Drawer";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import GradingIcon from "@mui/icons-material/Grading";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Stepper, Step, StepLabel } from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import OBCApi from "../../api/api";
import { gigApplicationEntity } from "../../api/types";
import applicationsuccess from "../../assets/images/applicationsuccess.svg";
import { useParams, Link } from "react-router-dom";
import Countdown from "./Countdown";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import QRScanModal from "./QRScanModal";
export default function EventDetailPage({ isOpen, onClose, eventData }) {
    console.log(eventData);
  const { chain } = useParams();
  const [viewState, setViewState] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const [activeStepIndex, setActiveStepIndex] = useState(0);

const calculateDaysAgo = (createdDate) => {
  const currentDate = new Date();
  const postedDate = new Date(createdDate);
  const timeDifference = currentDate - postedDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (daysDifference === 0) {
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    return hoursDifference > 0
      ? `${hoursDifference} ${hoursDifference === 1 ? "hour" : "hours"} ago`
      : "Less than an hour ago";
  }

  return `${daysDifference} ${daysDifference === 1 ? "day" : "days"} ago`;
};

  const targetTime = new Date(eventData?.expiry_date).getTime();

  const handleFormSubmit = () => {
    setShowScanner(true);
    // setShowLoading(true);
    // const user = JSON.parse(localStorage.getItem("user"));
    // const api = new OBCApi(localStorage.getItem("jwt"), chain);
    // const entity = gigApplicationEntity;
    // entity.chain = chain;
    // entity.gig_id = eventData.ID;
    // entity.skills = user.skills;
    // api
    //   .gigAppCreate(entity)
    //   .then((res) => {
    //     setShowLoading(false);
    //     showSuccessSnack("gig application submitted succesfully!");
    //     setViewState(2);
    //   })
    //   .catch((err) => {
    //     setShowLoading(false);
    //     showErrorSnack(err);
    //   });
  };
    const [showScanner, setShowScanner] = useState(false);

  return (
    <Drawer anchor={"bottom"} open={isOpen} className="eventdetailpage">
      <div
        style={{ filter: showLoading ? "blur(20px)" : "none" }}
        className="eventdetailpage_width"
      >
        {eventData && <QRScanModal
          showScanner={showScanner}
          setShowScanner={setShowScanner}
          eventID={eventData.id}
        />}

        <Loader showLoading={showLoading} setShowLoading={setShowLoading} />

        <div className="eventdetailpage_closecontainer">
          <button
            className="eventdetailpage_closecontainer_closebutton"
            onClick={() => {
              setViewState(0);
              onClose();
            }}
          >
            X
          </button>
        </div>

        <div className="eventdetailpage_main">
          {console.log(eventData)}
          <div className="eventdetailpage_main_left">
            {viewState !== 2 ? (
              <>
                {/* <div className="eventdetailpage_main_left_top">
                  <div className="eventdetailpage_main_left_top_type">
                    {eventData?.category}
                  </div>
                  <div className="eventdetailpage_main_left_top_amount">{`🏆 ${eventData?.bounty_amount}`}</div>
                </div> */}
                <div className="eventdetailpage_main_left_name">
                  {eventData?.name}
                </div>
                <div className="eventdetailpage_main_left_creatordetails">
                  {/* <div className="eventdetailpage_main_left_creatordetails_image">
                    <img src={eventData?.img} alt="gig" />
                  </div> */}
                  {/* <div className="eventdetailpage_main_left_creatordetails_by">
                    by {eventData?.organisation_username}
                  </div> */}
                  <div className="eventdetailpage_main_left_creatordetails_date">
                    {eventData?.date}, {eventData?.time}
                  </div>
                </div>
                <div className="eventdetailpage_main_left_divider"></div>
                {viewState === 0 ? (
                  <div className="eventdetailpage_main_left_detailscontainer">
                    <MDEditor.Markdown
                      source={eventData?.description}
                      className="eventdetailpage_main_left_detailscontainer_value"
                      style={{ whiteSpace: "pre-wrap" }}
                    />
                  </div>
                ) : (
                  <div className="eventdetailpage_main_left_detailscontainer">
                    <div
                      onClick={() => {
                        setViewState(0);
                      }}
                      className="eventdetailpage_main_left_detailscontainer_subtext"
                    >
                      {`<`} Back to Event Details
                    </div>

                    <div className="eventdetailpage_main_left_detailscontainer_submitform">
                      <div
                        onClick={handleFormSubmit}
                        className="eventdetailpage_main_left_detailscontainer_submitform_btn"
                      >
                        Mark Attendance
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {" "}
                <div className="eventdetailpage_main_left_success">
                  <div className="eventdetailpage_main_left_success_icon">
                    <img src={applicationsuccess} alt="success" />
                  </div>
                  <div className="eventdetailpage_main_left_success_title">
                    Your submission was successful!
                  </div>
                  <div className="eventdetailpage_main_left_success_subtitle">
                    Keep an eye on your mailbox for further updates 💪
                  </div>
                  <div
                    onClick={() => {
                      setViewState(0);
                      onClose();
                    }}
                    className="eventdetailpage_main_left_success_submit"
                  >
                    Go to gigs
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="eventdetailpage_main_right">
            <div className="eventdetailpage_main_right_container prize">
              <div className="prize_title">Reward</div>
              {/* <div className="prize_info">
                Bounty Amount: {eventData?.bounty_amount}
              </div> */}
              <div className="prize_imagecontainer">
                <img src={eventData?.img} alt="NFT" />
              </div>
              <div className="prize_info">
                Claim this cool NFT when you attend the event!
              </div>

              <div className="eventdetailpage_main_right_container submission">
                {/* <div>
                <div className="submission_title">Time Remaining</div>
                <div className="submission_value">
                  <Countdown targetTime={targetTime} />
                </div>
              </div> */}
                {viewState === 0 && (
                  <div
                    onClick={() => handleFormSubmit()}
                    className="submission_button"
                  >
                    Mark Attendance
                  </div>
                )}
              </div>
            </div>
            {/* <div className="eventdetailpage_main_right_container project">
            <div className="project_title">About the Project</div>
            <div className="project_detail">
              <div className="project_detail_image">
                <img src={eventData?.icon} alt="project icon" />
              </div>
              <div className="project_detail_others">
                <div className="project_detail_others_data">
                  <Link
                    to="/org/keybase"
                    className="project_detail_others_data_name"
                  >
                    Keybase
                  </Link>
                  <div className="project_detail_others_data_status">
                    Verified
                  </div>
                </div>
                <div className="project_detail_others_tags">
                  <div className="project_detail_others_tags_tag">DeFi</div>
                  <div className="project_detail_others_tags_tag">Wallets</div>
                </div>
              </div>
            </div>
          </div> */}
            {/* <div className="eventdetailpage_main_right_container eligibility">
              <div className="eligibility_title">Eligibility</div>
              <div className="eligibility_text">
                User needs to be from India only
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Drawer>
  );
}
