import React, { useEffect, useState } from "react";
import "./Login.scss";
import zoonboarding from "../../assets/images/zo-onboarding.svg";
import { Link } from "react-router-dom";
import Header from "../../components/Header/StellarHeader/Header";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { CHAIN_NAMESPACES, IProvider, WALLET_ADAPTERS } from "@web3auth/base";
import RPC from "./web3RPC.ts"; // for using web3.js
import OBCApi from "../../api/api.js";

// // Initialize within useEffect()
// const web3auth = new Web3AuthNoModal({
//   clientId:
//     "BOtu5KU_MzM-wWqqlND-9AAbpi7EzkDzbb4YBJL1vB1tvsAaXWkdu5ChkAelVxisHM4rsVIL2tLF4365f_vgUfE", // Get your Client ID from the Web3Auth Dashboard
//   web3AuthNetwork: "sapphire_devnet", // Web3Auth Network
//   chainConfig: {
//     chainNamespace: "eip155",
//     chainId: "0x1",
//     rpcTarget: "https://rpc.ankr.com/eth",
//     displayName: "Ethereum Mainnet",
//     blockExplorer: "https://goerli.etherscan.io",
//     ticker: "ETH",
//     tickerName: "Ethereum",
//   },
// });

// await web3auth.initModal();
export default function Login({ web3auth, loggedIn, setLoggedIn }) {
  const [provider, setProvider] = useState(null);
  const [address, setAddress] = useState("");

  // useEffect(() => {
  //   const init = async () => {
  //     try {
  //       const web3auth = new Web3AuthNoModal({
  //         clientId:
  //           "BOtu5KU_MzM-wWqqlND-9AAbpi7EzkDzbb4YBJL1vB1tvsAaXWkdu5ChkAelVxisHM4rsVIL2tLF4365f_vgUfE", // Get your Client ID from the Web3Auth Dashboard
  //         web3AuthNetwork: "sapphire_devnet", // Web3Auth Network
  //         chainConfig: {
  //           chainNamespace: "eip155",
  //           chainId: "0x1",
  //           rpcTarget: "https://rpc.ankr.com/eth",
  //           displayName: "Ethereum Mainnet",
  //           blockExplorer: "https://goerli.etherscan.io",
  //           ticker: "ETH",
  //           tickerName: "Ethereum",
  //         },
  //       });
  //       const chainConfig = {
  //         chainNamespace: "eip155",
  //         chainId: "0x1",
  //         rpcTarget: "https://rpc.ankr.com/eth",
  //         displayName: "Ethereum Mainnet",
  //         blockExplorer: "https://goerli.etherscan.io",
  //         ticker: "ETH",
  //         tickerName: "Ethereum",
  //       };
  //       const privateKeyProvider = new EthereumPrivateKeyProvider({
  //         config: {
  //           chainConfig,
  //         },
  //       });

  //       const openloginAdapter = new OpenloginAdapter({
  //         loginSettings: {
  //           mfaLevel: "none",
  //         },
  //         adapterSettings: {
  //           uxMode: "redirect",
  //           whiteLabel: {
  //             appName: "Onboarding.club",
  //             appUrl: "https://onboarding.club",
  //             logoLight: "https://i.ibb.co/SNCtLK4/Group-1171279303.png",
  //             logoDark: "https://i.ibb.co/SNCtLK4/Group-1171279303.png",
  //             defaultLanguage: "en",
  //             mode: "dark",
  //           },
  //           mfaSettings: {
  //             deviceShareFactor: {
  //               enable: true,
  //               priority: 1,
  //               mandatory: true,
  //             },
  //             backUpShareFactor: {
  //               enable: true,
  //               priority: 2,
  //               mandatory: false,
  //             },
  //             socialBackupFactor: {
  //               enable: true,
  //               priority: 3,
  //               mandatory: false,
  //             },
  //             passwordFactor: {
  //               enable: true,
  //               priority: 4,
  //               mandatory: false,
  //             },
  //           },
  //         },
  //         privateKeyProvider,
  //       });
  //       web3auth.configureAdapter(openloginAdapter);
  //       setWeb3auth(web3auth);

  //       const defaultWcSettings = await getWalletConnectV2Settings(
  //         "eip155",
  //         [1],
  //         "04309ed1007e77d1f119b85205bb779d"
  //       );
  //       const walletConnectModal = new WalletConnectModal({
  //         projectId: "04309ed1007e77d1f119b85205bb779d",
  //       });
  //       const walletConnectV2Adapter = new WalletConnectV2Adapter({
  //         adapterSettings: {
  //           qrcodeModal: walletConnectModal,
  //           ...defaultWcSettings.adapterSettings,
  //         },
  //         loginSettings: { ...defaultWcSettings.loginSettings },
  //       });

  //       web3auth.configureAdapter(walletConnectV2Adapter);

  //       // adding metamask adapter
  //       const metamaskAdapter = new MetamaskAdapter({
  //         clientId:
  //           "BOtu5KU_MzM-wWqqlND-9AAbpi7EzkDzbb4YBJL1vB1tvsAaXWkdu5ChkAelVxisHM4rsVIL2tLF4365f_vgUfE",
  //         sessionTime: 3600, // 1 hour in seconds
  //         web3AuthNetwork: "sapphire_devnet",
  //         chainConfig: {
  //           chainNamespace: "eip155",
  //           chainId: "0x1",
  //           rpcTarget: "https://rpc.ankr.com/eth", // This is the public RPC we have added, please pass on your own endpoint while creating an app
  //         },
  //       });
  //       metamaskAdapter.setAdapterSettings({
  //         sessionTime: 86400, // 1 day in seconds
  //         chainConfig: {
  //           chainNamespace: "eip155",
  //           chainId: "0x1",
  //           rpcTarget: "https://rpc.ankr.com/eth", // This is the public RPC we have added, please pass on your own endpoint while creating an app
  //         },
  //         web3AuthNetwork: "sapphire_devnet",
  //       });

  //       web3auth.configureAdapter(metamaskAdapter);

  //       await web3auth.init();
  //       setProvider(web3auth.provider);
  //       console.log("hello");
  //       // if (web3auth.connected && !localStorage.getItem("jwt")){
  //       //  await web3auth.logout();
  //       // }
  //         if (web3auth.connected) {
  //           console.log(provider);
  //           setLoggedIn(true);
  //           const idToken = await web3auth.authenticateUser();
  //           const api = new OBCApi();

  //           api
  //             .loginUser(idToken)
  //             .then((res) => {
  //               setShowLoading(false);
  //               console.log(res.data.data.token);
  //               localStorage.setItem("jwt", res.data.data.token);
  //             })
  //             .catch((err) => {
  //               setShowLoading(false);
  //               showErrorSnack(err);
  //             });
  //           console.log(idToken);
  //         }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   init();
  // }, []);
  const authenticateUserJWT = async () => {
    try {
      if (web3auth.connected) {
        console.log(provider);
        const idToken = await web3auth.authenticateUser();
        const api = new OBCApi();

        api
          .loginUser(idToken)
          .then((res) => {
            setShowLoading(false);
            console.log(res.data.data.token);
            localStorage.setItem("jwt", res.data.data.token);
            setLoggedIn(true);
            showSuccessSnack("Logged in successfully!")
            navigate("/");
          })
          .catch((err) => {
            setShowLoading(false);
            showErrorSnack(err);
          });
        console.log(idToken);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const { chain } = useParams();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const navigate = useNavigate();

  const handleSubmit = () => {
    setIsSubmitted(true);
  };
  const login = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    if (!loggedIn) {
      const web3authProvider = await web3auth.connectTo(
        WALLET_ADAPTERS.OPENLOGIN,
        {
          loginProvider: "google",
        }
      );
      console.log(web3authProvider);

      setProvider(web3authProvider);
      authenticateUserJWT();
    }
  };
  const loginwithMetamask = async () => {
    try {
      if (!web3auth) {
        console.log("web3auth not initialized yet");
        return;
      }
      if (!loggedIn) {
        const web3authProvider = await web3auth.connectTo(
          WALLET_ADAPTERS.METAMASK
        );
        console.log(web3authProvider);
        setProvider(web3authProvider);
        authenticateUserJWT();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const loginWithWC = async () => {
    try {
      if (!web3auth) {
        console.log("web3auth not initialized yet");
        return;
      }
      if (!loggedIn) {
        const web3authProvider = await web3auth.connectTo(
          WALLET_ADAPTERS.WALLET_CONNECT_V2
        );

        console.log(web3authProvider);
        setProvider(web3authProvider);
        authenticateUserJWT();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getAccounts = async () => {
    if (!web3auth?.provider) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new RPC(web3auth.provider);
    const address = await rpc.getAccounts();
    console.log(address);
    setAddress(address[0]);
  };
  const getIDToken = async () => {
    try {
      if (!web3auth) {
        console.log("web3auth not initialized yet");
        return;
      }
      const idToken = await web3auth.authenticateUser();
      console.log(idToken);
    } catch (err) {
      console.log(err.message);
    }
  };
  const logout = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    if (loggedIn) {
      await web3auth.logout();
      setLoggedIn(false);
    }
  };

  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="login"
    >
      {/* <Header /> */}

      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />

      <div className="login_main">
        <div className="login_main_login">Get Started With</div>
        <div className="login_main_title">
          <img src={zoonboarding} alt="Zo Onboarding" />
        </div>
        <div className="login_main_subtitle">
          Get ready to dive deep into the Zo world
        </div>
        <div className="login_main_container">
          <div
            onClick={login}
            className="login_main_container_googlebutton flex"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_164_1367)">
                <path
                  d="M19.8042 10.2302C19.8042 9.55044 19.7491 8.86699 19.6315 8.19824H10.1992V12.0491H15.6007C15.3765 13.291 14.6563 14.3897 13.6018 15.0878V17.5864H16.8243C18.7166 15.8448 19.8042 13.2726 19.8042 10.2302Z"
                  fill="#4285F4"
                />
                <path
                  d="M10.1999 20.0008C12.897 20.0008 15.1714 19.1152 16.8286 17.5867L13.6061 15.088C12.7096 15.698 11.5521 16.0434 10.2036 16.0434C7.59474 16.0434 5.38272 14.2833 4.58904 11.917H1.26367V14.4928C2.96127 17.8696 6.41892 20.0008 10.1999 20.0008Z"
                  fill="#34A853"
                />
                <path
                  d="M4.58467 11.9168C4.16578 10.6748 4.16578 9.32995 4.58467 8.08799V5.51221H1.26297C-0.155365 8.33785 -0.155365 11.6669 1.26297 14.4925L4.58467 11.9168Z"
                  fill="#FBBC04"
                />
                <path
                  d="M10.1999 3.95805C11.6256 3.936 13.0035 4.47247 14.036 5.45722L16.8911 2.60218C15.0833 0.904587 12.6838 -0.0287217 10.1999 0.000673888C6.41892 0.000673888 2.96126 2.13185 1.26367 5.51234L4.58537 8.08813C5.37537 5.71811 7.59106 3.95805 10.1999 3.95805Z"
                  fill="#EA4335"
                />
              </g>
              <defs>
                <clipPath id="clip0_164_1367">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <div className="login_main_container_googlebutton_text">
              Continue with Google
            </div>
          </div>
          <div
            onClick={loginwithMetamask}
            className="login_main_container_googlebutton hidden xl:flex"
          >
            <svg
              width="30"
              height="27"
              viewBox="0 0 30 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_777_5149)">
                <path
                  d="M27.6432 0.0288086L16.3027 8.45119L18.4003 3.48194L27.6432 0.0288086Z"
                  fill="#E2761B"
                  stroke="#E2761B"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.46484 0.0288086L12.7142 8.53089L10.7192 3.48194L1.46484 0.0288086Z"
                  fill="#E4761B"
                  stroke="#E4761B"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M23.5632 19.5518L20.543 24.1792L27.0054 25.9568L28.8633 19.6544L23.5632 19.5518Z"
                  fill="#E4761B"
                  stroke="#E4761B"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M0.267578 19.6544L2.11399 25.9568L8.57642 24.1792L5.55622 19.5518L0.267578 19.6544Z"
                  fill="#E4761B"
                  stroke="#E4761B"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.21127 11.7332L6.41016 14.4575L12.8267 14.7425L12.5991 7.84717L8.21127 11.7332Z"
                  fill="#E4761B"
                  stroke="#E4761B"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M20.8958 11.7333L16.4512 7.76758L16.3027 14.7426L22.7084 14.4577L20.8958 11.7333Z"
                  fill="#E4761B"
                  stroke="#E4761B"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.57812 24.1793L12.4304 22.2984L9.10223 19.6997L8.57812 24.1793Z"
                  fill="#E4761B"
                  stroke="#E4761B"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.6797 22.2984L20.5434 24.1793L20.0078 19.6997L16.6797 22.2984Z"
                  fill="#E4761B"
                  stroke="#E4761B"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M20.5434 24.1796L16.6797 22.2988L16.9876 24.8179L16.9532 25.8775L20.5434 24.1796Z"
                  fill="#D7C1B3"
                  stroke="#D7C1B3"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.57812 24.1796L12.1683 25.8775L12.1454 24.8179L12.4304 22.2988L8.57812 24.1796Z"
                  fill="#D7C1B3"
                  stroke="#D7C1B3"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.2238 18.0362L9.00977 17.09L11.2776 16.0527L12.2238 18.0362Z"
                  fill="#233447"
                  stroke="#233447"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.8848 18.0362L17.8309 16.0527L20.1102 17.09L16.8848 18.0362Z"
                  fill="#233447"
                  stroke="#233447"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.57684 24.1792L9.12388 19.5518L5.55664 19.6544L8.57684 24.1792Z"
                  fill="#CD6116"
                  stroke="#CD6116"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.9961 19.5518L20.5431 24.1792L23.5633 19.6544L19.9961 19.5518Z"
                  fill="#CD6116"
                  stroke="#CD6116"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22.7084 14.4575L16.3027 14.7425L16.8956 18.0362L17.8418 16.0528L20.1211 17.0901L22.7084 14.4575Z"
                  fill="#CD6116"
                  stroke="#CD6116"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.00889 17.0901L11.2882 16.0528L12.2229 18.0362L12.8267 14.7425L6.41016 14.4575L9.00889 17.0901Z"
                  fill="#CD6116"
                  stroke="#CD6116"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.41016 14.4575L9.10006 19.6997L9.00889 17.0901L6.41016 14.4575Z"
                  fill="#E4751F"
                  stroke="#E4751F"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M20.1219 17.0901L20.0078 19.6997L22.7092 14.4575L20.1219 17.0901Z"
                  fill="#E4751F"
                  stroke="#E4751F"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.8265 14.7427L12.2227 18.0364L12.975 21.9225L13.1459 16.8053L12.8265 14.7427Z"
                  fill="#E4751F"
                  stroke="#E4751F"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.3034 14.7427L15.9961 16.7938L16.1326 21.9225L16.8964 18.0364L16.3034 14.7427Z"
                  fill="#E4751F"
                  stroke="#E4751F"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.8966 18.0365L16.1328 21.9225L16.6799 22.2987L20.008 19.7L20.1221 17.0903L16.8966 18.0365Z"
                  fill="#F6851B"
                  stroke="#F6851B"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.00977 17.0903L9.10094 19.7L12.4291 22.2987L12.9761 21.9225L12.2238 18.0365L9.00977 17.0903Z"
                  fill="#F6851B"
                  stroke="#F6851B"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.9546 25.8771L16.989 24.8174L16.704 24.5663H12.4074L12.1454 24.8174L12.1683 25.8771L8.57812 24.1792L9.83162 25.2045L12.373 26.9712H16.7384L19.2913 25.2045L20.5448 24.1792L16.9546 25.8771Z"
                  fill="#C0AD9E"
                  stroke="#C0AD9E"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.6803 22.2985L16.1332 21.9224H12.9766L12.4295 22.2985L12.1445 24.8176L12.4066 24.5664H16.7032L16.9882 24.8176L16.6803 22.2985Z"
                  fill="#161616"
                  stroke="#161616"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M28.1228 8.99823L29.0913 4.34837L27.644 0.0288086L16.6797 8.1662L20.8966 11.7334L26.8573 13.4772L28.1796 11.9387L27.6096 11.5287L28.5214 10.6967L27.8149 10.1497L28.7266 9.45409L28.1228 8.99823Z"
                  fill="#763D16"
                  stroke="#763D16"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M0.0273438 4.34837L0.995848 8.99823L0.38057 9.45409L1.29231 10.1497L0.597322 10.6967L1.50906 11.5287L0.93908 11.9387L2.24992 13.4772L8.21061 11.7334L12.4275 8.1662L1.46318 0.0288086L0.0273438 4.34837Z"
                  fill="#763D16"
                  stroke="#763D16"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M26.8573 13.4767L20.8966 11.7329L22.7092 14.4572L20.0078 19.6994L23.5636 19.6541H28.8637L26.8573 13.4767Z"
                  fill="#F6851B"
                  stroke="#F6851B"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.21172 11.7329L2.25103 13.4767L0.267578 19.6541H5.55622L9.10052 19.6994L6.41061 14.4572L8.21172 11.7329Z"
                  fill="#F6851B"
                  stroke="#F6851B"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.3033 14.7427L16.6794 8.16619L18.4123 3.48193H10.7188L12.4287 8.16619L12.8272 14.7427L12.9643 16.8168L12.9757 21.9225H16.1324L16.1553 16.8168L16.3033 14.7427Z"
                  fill="#F6851B"
                  stroke="#F6851B"
                  stroke-width="0.0573419"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_777_5149">
                  <rect width="29.1199" height="27" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <div className="login_main_container_googlebutton_text">
              Login with Metamask
            </div>
          </div>
          <div
            onClick={loginWithWC}
            className="login_main_container_googlebutton flex xl:hidden"
          >
            <svg
              width="45"
              height="31"
              viewBox="0 0 45 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8228 8.77564C17.67 3.0507 27.1502 3.0507 32.9976 8.77564L33.7012 9.46463C33.9936 9.75091 33.9936 10.215 33.7012 10.5013L31.294 12.8582C31.1477 13.0013 30.9108 13.0013 30.7645 12.8582L29.7962 11.9101C25.717 7.91618 19.1033 7.91618 15.0241 11.9101L13.987 12.9254C13.8409 13.0686 13.6039 13.0686 13.4577 12.9254L11.0504 10.5685C10.758 10.2822 10.758 9.81814 11.0504 9.53185L11.8228 8.77564ZM37.9761 13.6501L40.1186 15.7477C40.411 16.034 40.411 16.4981 40.1186 16.7844L30.4579 26.2432C30.1656 26.5294 29.6915 26.5294 29.3991 26.2432L22.5425 19.53C22.4694 19.4584 22.3509 19.4584 22.2778 19.53L15.4214 26.2432C15.1291 26.5294 14.655 26.5294 14.3626 26.2432L4.70169 16.7843C4.40933 16.498 4.40933 16.0339 4.70169 15.7476L6.8442 13.6499C7.13657 13.3637 7.61059 13.3637 7.90295 13.6499L14.7597 20.3632C14.8327 20.4348 14.9512 20.4348 15.0243 20.3632L21.8806 13.6499C22.173 13.3637 22.647 13.3637 22.9394 13.6499L29.7961 20.3632C29.8692 20.4348 29.9877 20.4348 30.0608 20.3632L36.9173 13.6501C37.2097 13.3638 37.6837 13.3638 37.9761 13.6501Z"
                fill="#3396FF"
              />
            </svg>
            Connect External Wallet
          </div>
          {/* <div className="text-white" onClick={logout}>
            Logout
          </div> */}
          <div className="text-white" onClick={getAccounts}>
            Get Accounts {address}
          </div>
          <div className="text-white" onClick={getIDToken}>
            Get ID Token
          </div>
        </div>
      </div>
    </div>
  );
}
