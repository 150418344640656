import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.scss";
import stellarlogo from "../../../assets/images/stellarclub.svg";
import flowlogo from "../../../assets/images/flowclub.svg";
import cosmoslogo from "../../../assets/images/cosmosclub.svg";
import onboardinglogo from "../../../assets/images/onboardingclublogo.svg";
import logouticon from "../../../assets/images/logout.svg";
import zoonboarding from "../../../assets/images/zo-onboarding.svg";
import useWindowSize from "../../../utils/windowSize";
import Loader from "../../Loader/Loader";

export default function Header({ web3auth, loggedIn, setLoggedIn }) {
  const { chain } = useParams();
  const location = useLocation();
  const size = useWindowSize();
  const navigate = useNavigate();

  const [showLoading, setShowLoading] = useState(false);
  const [profileData, setProfileData] = useState({ name: "Z" });

  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      const name = JSON.parse(localStorage.getItem("user"))?.name ?? "Z";
      setProfileData({ name });
      // setIsLogged(true);
    }
  }, []);

  const tabs = [
    {
      label: "Events",
      route: `/`,
      bgColor: "rgba(144, 247, 197, 0.12)",
      color: "#90F7C5",
      hasSoon: false,
    },
    {
      label: "Quests",
      route: `/quests`,
      bgColor: "rgba(249, 160, 110, 0.12)",
      color: "#F9A06E",
      hasSoon: false,
    },
    {
      label: "Leaderboard",
      route: `/leaderboard`,
      bgColor: "rgba(147, 157, 241, 0.12)",
      color: "#949DF1",
      hasSoon: false,
    },
  ];
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  useEffect(() => {}, []);

  const closeDropdown = (e) => {
    setDropdownOpen(false);
  };
  // useEffect(() => {
  //   if (isDropdownOpen) {
  //     window.addEventListener("click", closeDropdown);
  //   } else {
  //     window.removeEventListener("click", closeDropdown);
  //   }

  //   return () => {
  //     window.removeEventListener("click", closeDropdown);
  //   };
  // }, [isDropdownOpen]);
  const logout = async () => {
    setShowLoading(true);
    if (web3auth.connected) {
      await web3auth.logout();
      localStorage.removeItem("jwt");
      setLoggedIn(false);
    }
    setTimeout(()=>{setShowLoading(false)},"1000");
  };
  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="chainheader"
    >
      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />
      <div className="chainheader_inner">
        <div className="chainheader_inner_inner">
          <div
            onClick={() => {
              navigate("/");
            }}
            className="chainheader_inner_inner_left cursor-pointer"
          >
            <img src={zoonboarding} alt="onboarding logo" />
          </div>
          <div className="chainheader_inner_inner_right flex md:hidden">
            {/* <div className="chainheader_inner_inner_right_text">Login</div> */}
            {!loggedIn ? (
              <Link
                to={`/authenticate`}
                className="chainheader_inner_inner_right_button"
              >
                Authenticate
              </Link>
            ) : (
              <div></div>
            )}
            {!loggedIn ? (
              <div></div>
            ) : (
              <Link
                to="/profile"
                className="chainheader_inner_inner_right_profileimagecontainer ml-12"
              >
                {profileData.name ? profileData.name[0].toUpperCase() : ""}
              </Link>
            )}
            {loggedIn && (
              <div
                onClick={logout}
                className="chainheader_inner_inner_right_logoutbutton"
              >
                <img src={logouticon} alt="logout" />
              </div>
            )}
          </div>
          <div className="chainheader_inner_inner_center">
            {tabs.map((tab, index) => (
              <React.Fragment key={index}>
                {tab.hasSoon ? (
                  size.width > 1350 ? (
                    <div
                      className={`chainheader_inner_inner_center_tab cursor-not-allowed ${
                        location.pathname === tab.route ? "active-tab" : ""
                      }`}
                      style={{
                        backgroundColor:
                          location.pathname === tab.route ? tab.bgColor : "",
                        color: location.pathname === tab.route ? tab.color : "",
                      }}
                    >
                      {tab.label}
                      <span>Soon</span>
                    </div>
                  ) : null
                ) : (
                  <Link
                    to={tab.route}
                    className={`chainheader_inner_inner_center_tab ${
                      location.pathname === tab.route ? "active-tab" : ""
                    }`}
                    style={{
                      backgroundColor:
                        location.pathname === tab.route ? tab.bgColor : "",
                      color: location.pathname === tab.route ? tab.color : "",
                    }}
                  >
                    {tab.label}
                  </Link>
                )}
              </React.Fragment>
            ))}
          </div>

          <div className="chainheader_inner_inner_right hidden md:flex">
            {/* <div className="chainheader_inner_inner_right_text">Login</div> */}
            {!loggedIn ? (
              <Link
                to={`/authenticate`}
                className="chainheader_inner_inner_right_button"
              >
                Authenticate
              </Link>
            ) : (
              <div></div>
            )}

            <div className="dropdown">
              {/* <button
                onClick={toggleDropdown}
                className="chainheader_inner_inner_right_button"
              >
                New +
              </button> */}
              {isDropdownOpen && (
                <div className="dropdown-content">
                  <Link
                    onClick={() => {
                      setTimeout(() => {
                        setDropdownOpen(false);
                      }, 100);
                    }}
                    to={`/${chain}/register-org`}
                    className="dropdown-link"
                  >
                    Create Org
                  </Link>
                  <Link
                    onClick={() => {
                      setTimeout(() => {
                        setDropdownOpen(false);
                      }, 100);
                    }}
                    to={`/${chain}/gig/create`}
                    className="dropdown-link"
                  >
                    Post a Gig
                  </Link>
                  <div className="dropdown-link">
                    Host a Hack <span>Soon</span>
                  </div>
                </div>
              )}
            </div>
            {!loggedIn ? (
              <></>
            ) : (
              <Link
                to="/profile"
                className="chainheader_inner_inner_right_profileimagecontainer"
              >
                {profileData.name ? profileData.name[0].toUpperCase() : ""}
              </Link>
            )}
            {loggedIn && (
              <div
                onClick={logout}
                className="chainheader_inner_inner_right_logoutbutton"
              >
                <img src={logouticon} alt="logout" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
