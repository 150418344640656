import React, { useEffect, useState } from "react";
import Header from "../../components/Header/StellarHeader/Header";
import "./LeaderboardPage.scss";
import { useParams } from "react-router-dom";
import resources from "./resources.json";
import ResourceHubHeroGalaxy from "../../assets/images/hubelement.svg";
import { useNavigate } from "react-router-dom";

import gigImage from "../../assets/images/gigimage.svg";
import gigImage1 from "../../assets/images/222.jpg";
import gigImage2 from "../../assets/images/111.jpg";
import gigImage3 from "../../assets/images/333.jpg";

import { useSnackbar } from "notistack";
import Loader from "../../components/Loader/Loader";
import OBCApi from "../../api/api";

let contentBg;
let buildOnBg;

const allowedChains = ["stellar", "cosmos", "flow"];
const cards = [
  {
    title: "Morning Zo Zo",
    subtitle: "Have a spectacular sunset shot while at the top!",
    time: "08:00 PM",
    date: "25th November, 2023",
    img: gigImage1,
  },
  {
    title: "Morning Zo Zo",
    subtitle: "Have a spectacular sunset shot while at the top!",
    time: "08:00 PM",
    date: "26th November, 2023",
    img: gigImage,
  },
  {
    title: "Morning Zo Zo",
    subtitle: "Have a spectacular sunset shot while at the top!",
    time: "08:00 PM",
    date: "24th November, 2023",
    img: gigImage3,
  },
  {
    title: "Morning Zo Zo",
    subtitle: "Have a spectacular sunset shot while at the top!",
    time: "08:00 PM",
    date: "24th November, 2023",
    img: gigImage2,
  },
];
const leaderboard = [
  {
    userId: "9be7d94f-b9fc-4c5b-9362-16dfdf701cdd",
    zxp: 120,
    name: "Lzy Lad",
    avatar:
      "https://cdn.discordapp.com/avatars/407207216790372352/5c7ce8a39931e68817838a4194fc2be7",
    numberOfQuests: 12,
    addresses: {},
  },
  {
    userId: "dee3760d-68e0-4420-ad29-4cbb73750936",
    zxp: 106,
    name: "kunal.zed",
    avatar:
      "https://cdn.discordapp.com/avatars/599269054955520021/4adb92eefc3b2c29e600ac01ca15018d",
    numberOfQuests: 10,
    addresses: {},
  },
  {
    userId: "3e0b9405-72fc-41d3-a775-2033fa179f2b",
    zxp: 105,
    name: "Dexterart.eth",
    avatar:
      "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
    numberOfQuests: 4,
    addresses: {},
  },
  {
    userId: "536768dd-8f4e-407e-b792-98713c8d31f3",
    zxp: 90,
    name: "Helllll.yeahhhh",
    avatar:
      "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
    numberOfQuests: 11,
    addresses: {},
  },
  {
    userId: "6337f596-13f8-41ea-b4d2-21459736f7cc",
    zxp: 70,
    name: "Reet_s",
    avatar:
      "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
    numberOfQuests: 10,
    addresses: {},
  },
  {
    userId: "8974731a-3029-4753-90dd-62800a93153c",
    zxp: 60,
    name: "creodevi.eth",
    avatar:
      "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
    numberOfQuests: 9,
    addresses: {},
  },
  {
    userId: "89161f16-f69b-4e1e-8cc5-f52fa5e0c7ab",
    zxp: 60,
    name: "ripfarhan",
    avatar:
      "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
    numberOfQuests: 9,
    addresses: {},
  },
  {
    userId: "8a98cdbe-8cd6-4100-8f90-a3b4c074e72c",
    zxp: 60,
    name: "lyght.eth",
    avatar:
      "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
    numberOfQuests: 4,
    addresses: {},
  },
  {
    userId: "c71c0214-b6f8-4843-8e2f-1e4aff6b4f1d",
    zxp: 40,
    name: "Wufm",
    avatar:
      "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
    numberOfQuests: 4,
    addresses: {},
  },
  {
    userId: "329b2be8-eca0-4a7f-a459-325912659469",
    zxp: 30,
    name: "Zordieee",
    avatar:
      "https://cdn.discordapp.com/avatars/739461334621618277/705f596b148ba9f6fc20415664b13326",
    numberOfQuests: 3,
    addresses: {},
  },
  {
    userId: "3192e9e5-17c3-491b-b02a-e9a07b0d972f",
    zxp: 30,
    name: "svrnjxn",
    avatar:
      "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/3e0b9405-72fc-41d3-a775-2033fa179f2b-7.jpg",
    numberOfQuests: 3,
    addresses: {},
  },
  {
    userId: "b8504c8e-e315-46cd-a81e-1358c6eff609",
    zxp: 30,
    name: "Mirzadmakhdm.eth",
    avatar:
      "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/b8504c8e-e315-46cd-a81e-1358c6eff609-logo.png",
    numberOfQuests: 3,
    addresses: {},
  },
  {
    userId: "9721dd47-25ea-419f-8577-6636534a099c",
    zxp: 30,
    name: "nfteawala",
    avatar:
      "https://cdn.discordapp.com/avatars/913540828650160168/168d961022cee78caec8be4007fb7965",
    numberOfQuests: 3,
    addresses: {},
  },
  {
    userId: "38e9c8eb-f7a1-4739-a227-25ce8d92f032",
    zxp: 20,
    name: "bhand.eth",
    avatar:
      "https://zealy-webapp-images-prod.s3.eu-west-1.amazonaws.com/public/38e9c8eb-f7a1-4739-a227-25ce8d92f032-t1kv828x_400x400.png",
    numberOfQuests: 2,
    addresses: {},
  },
];


export default function LeaderboardPage() {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const { chain } = useParams();
  const [activeEvent, setActiveEvent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
      const openModal = (card) => {
        setActiveEvent(card);

        console.log(activeEvent);
        setIsModalOpen(true);
        document.querySelector(".events").classList.add("modal-open");
      };

      const closeModal = () => {
        setIsModalOpen(false);
        document.querySelector(".events").classList.remove("modal-open");
      };
    const groupedCards = cards.reduce((acc, card) => {
      const date = card.date;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(card);
      return acc;
    }, {});

  const [leaderboardData, setLeaderboardData] = useState([]);
  const chainResources = resources[chain] || resources["others"];
    useEffect(() => {
      setShowLoading(true);
      const api = new OBCApi();
      api
        .getLeaderboard()
        .then((res) => {
          setShowLoading(false);
          console.log(res.data.data);
          const bdaa = res.data.data.users;
          bdaa.forEach((element) => {
            element.icon = gigImage;
          });
          setLeaderboardData(res.data.data.users); //hard coded data for the time being
        })
        .catch((err) => {
          setShowLoading(false);
          showErrorSnack(err);
        });
    }, []);
    const sortedLeaderboard = leaderboardData.sort((a, b) => b.xp - a.xp);

  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="events"
    >
      {/* <Header /> */}

      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />
      <div className="events_banner">
        <div className="events_banner_left">
          <div className="events_banner_left_title">/leaderboard</div>
          <div className="events_banner_left_subtitle">{`\\mani-fest/`}</div>
        </div>
        <div className="events_banner_right">
          <img src={ResourceHubHeroGalaxy} alt="gig" />
        </div>
      </div>
      <div className="events_main">
        <div className="events_main_title">Compete to be the best</div>
        <div className="events_main_subtitle">
          The top 3 people inn the leaderboard at the end of Manifest get
          exciting merch!
        </div>
        <div className="events_main_leaderboardcontainer">
          {sortedLeaderboard.map((user, index) => (
            <>
              <div key={index} className="events_main_leaderboardcontainer_row">
                <div className="events_main_leaderboardcontainer_row_cell index">
                  {index + 1}
                </div>
                <div className="events_main_leaderboardcontainer_row_cell avatar">
                  {" "}
                  <img src={user?.profilePhoto} alt="user avatar" />{" "}
                </div>
                <div className="events_main_leaderboardcontainer_row_cell name">{`${user?.walletAddress.substring(
                  0,
                  4
                )}...${user?.walletAddress.slice(-4)}`}</div>
                <div className="events_main_leaderboardcontainer_row_cell points">
                  {user?.xp}
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
