import React, { useEffect, useState } from "react";
import "./Modal.scss";
import { Link } from "react-router-dom";
import EditableMultiInput from "../CreateQuestPage/EditableMultiInput";
import OBCApi from "../../api/api";
import { useSnackbar } from "notistack";

export default function EditUserModal({ user, onClose }) {
  console.log(user);
  const [formData, setFormData] = useState({ xp: user.xp, tags: user.tags });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSetValue = (property, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [property]: newValue,
    }));
  };

  const handleSubmit = () => {
    const XP = {
      userId: user.id,
      adminId: 1,
      xp: Number(formData.xp),
    };
    const tags = {
      userId: user.id,
      tags: formData.tags,
    };
    const api = new OBCApi(localStorage.getItem("adminjwt"), "");
    api
      .adminUpdateXP(XP)
      .then((res) => {
            api.adminAddTags(tags).then((res) => {
              console.log(res.data.data);
              onClose();
            })
      })
      .catch((err) => {
        setIsSubmitted(true);
        showErrorSnack(err.message);
      });
  };
  return (
    <div className="loginModal">
      <div className="loginModal_content">
        <div className="loginModal_content_header">
          <div className="loginModal_content_header_title">Admin Login</div>
          <div onClick={onClose} className="loginModal_content_header_cross">
            X
          </div>
        </div>
        <div className="loginModal_content_main">
          <div className="loginModal_content_main_container">
            <div className="loginModal_content_main_container_inputcontainer">
              <div className="loginModal_content_main_container_title">
                Username
              </div>
              <div className="loginModal_content_main_container_subtitle">
                {user.username}
              </div>

            </div>
            <div className="loginModal_content_main_container_inputcontainer">
              <div className="loginModal_content_main_container_title">
                XP
              </div>
              <input
                className="loginModal_content_main_container_input"
                type="number"
                name="xp"
                placeholder="Enter XP to be added"
                value={formData?.xp}
                onChange={handleInputChange}
              />
            </div>
            <div className="profilemodal_content_main_container_inputcontainer">
              <div className="profilemodal_content_main_container_title">
                Roles
              </div>
              <EditableMultiInput
                property="tags"
                value={formData?.tags}
                setValue={handleSetValue}
              />
            </div>
          </div>
        </div>
        <div className="loginModal_content_footer">
          <div
            onClick={handleSubmit}
            className="loginModal_content_footer_button"
          >
            Submit
          </div>
        </div>
      </div>
    </div>
  );
}
