import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import gigImage from "../../assets/images/gigimage.svg";
import { useSnackbar } from "notistack";
import OBCApi from "../../api/api";
import delete_button from "../../assets/images/delete_button.png";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";

export default function QuestsList({
  renderdomain_tagsWithDynamicLimit,
  openQuestModal,
}) {
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [questStatuses, setQuestStatuses] = useState({});
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedQuestId, setSelectedQuestId] = useState("");
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const [cardsData, setCardsData] = useState([]);
  useEffect(() => {
    setShowLoading(true);
    const api = new OBCApi();
    api
      .getQuests()
      .then((res) => {
        setShowLoading(false);
        const quests = res.data.data.quests || [];

        // Extract quest statuses and set them in state
        const statusMap = quests.reduce((acc, quest) => {
          acc[quest.id] = quest.status;
          return acc;
        }, {});
        setQuestStatuses(statusMap);

        setCardsData(quests);
      })
      .catch((err) => {
        setShowLoading(false);
        showErrorSnack(err);
      });
  }, []);

  const editQuest = (card) => {
    navigate("/admin/gig/edit", { state: card });
  };

  const openDeleteConfirmation = (questId) => {
    setSelectedQuestId(questId);
    setConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setSelectedQuestId("");
    setConfirmationOpen(false);
  };

  const deleteQuest = (questData) => {
    const api = new OBCApi(localStorage.getItem("adminjwt"));
    console.log(localStorage.getItem("adminjwt"));
    console.log(questData);

    const data = {
      questID: questData.id,
    };

    console.log("deleting");
    api
      .deleteQuest(data)
      .then(() => {
        showSuccessSnack("Quest deleted successfully!");
        api
          .getQuests()
          .then((res) => {
            setShowLoading(false);
            console.log(res.data.data);
            setCardsData(res.data.data.quests);
          })
          .catch((err) => {
            setShowLoading(false);
            showErrorSnack(err);
          });
      })
      .catch((err) => {
        console.log(err);
        showErrorSnack(err.response.data.message);
      });
  };

  const toggleQuest = (questID, status) => {
    const data = { questID, status };
    const api = new OBCApi(localStorage.getItem("adminjwt"));

    const newStatus = status === "active" ? "inactive" : "active"; // Determine the new status

    api
      .toggleQuest(data)
      .then(() => {
        showSuccessSnack(
          `Quest ${
            status === "active" ? "activated" : "deactivated"
          } successfully!`
        );
        // Update questStatuses after successful toggling
        setQuestStatuses((prevStatuses) => ({
          ...prevStatuses,
          [questID]: newStatus,
        }));
        api
          .getQuests()
          .then((res) => {
            setShowLoading(false);
            const quests = res.data.data.quests || [];

            // Extract quest statuses and set them in state
            const statusMap = quests.reduce((acc, quest) => {
              acc[quest.id] = quest.status;
              return acc;
            }, {});
            setQuestStatuses(statusMap);

            setCardsData(quests);
          })
          .catch((err) => {
            setShowLoading(false);
            showErrorSnack(err);
          });
      })
      .catch((err) => {
        console.log(err);
        showErrorSnack(err.response.data.message);
      });
  };

  return (
    <div className=" orgpage_main_infocontainer_cardscontainer">
      <Dialog
        PaperProps={{
          sx: {
            background: `linear-gradient(to right, #1A1A1A, #0F0F0F)`,
            border: "2px solid #2D2D2D",
            width: "100%",
          },
        }}
        open={isConfirmationOpen}
        onClose={closeDeleteConfirmation}
      >
        <DialogTitle sx={{ color: "white", font: "semibold" }}>
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "white", font: "semibold" }}>
            Are you sure you want to delete this quest?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirmation}>Cancel</Button>
          <Button
            onClick={() => {
              deleteQuest({ id: selectedQuestId });
              closeDeleteConfirmation();
            }}
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div className=" orgpage_main_infocontainer_cardscontainer_cards">
        {cardsData.map((card, index) => (
          <div
            key={index}
            className="orgpage_main_infocontainer_cardscontainer_cards_card"
          >
            <div className="orgpage_main_infocontainer_cardscontainer_cards_card_top">
              <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left">
                <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details">
                  <div
                    className={`orgpage_main_infocontainer_cardscontainer_cards_card_right_submission ${
                      card.status === "active" ? "open" : "closed"
                    }`}
                  >
                    {card.status === "active"
                      ? "Submissions Open"
                      : "Submissions Closed"}
                  </div>

                  <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_name">
                    {" "}
                    {card.name}
                  </div>
                  {/* <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_by">
                          by {card.createdBy}
                        </div> */}
                  <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_desc">
                    {" "}
                    {card.about}
                  </div>
                </div>
              </div>
              <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right">
                <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_buttoncontainer">
                  <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_buttoncontainer_togglebutton">
                    <Switch
                      checked={questStatuses[card.id] === "active"}
                      onChange={(event) => {
                        const newStatus = event.target.checked
                          ? "active"
                          : "inactive";
                        setQuestStatuses({
                          ...questStatuses,
                          [card.id]: newStatus,
                        });
                        toggleQuest(card.id, newStatus);
                      }}
                      classes={{
                        checked: "custom-switch-checked",
                        thumb: "custom-switch-thumb",
                        track:
                          questStatuses[card.id] === "active"
                            ? "custom-switch-track-checked"
                            : "custom-switch-track-unchecked",
                      }}
                    />
                  </div>
                  <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_buttoncontainer_button">
                    <img
                      src={delete_button}
                      onClick={() => openDeleteConfirmation(card.id)}
                      alt="Delete"
                    />
                  </div>
                  <div
                    onClick={() => editQuest(card)}
                    className="orgpage_main_infocontainer_cardscontainer_cards_card_right_buttoncontainer_button"
                  >
                    Edit
                  </div>
                  <div
                    onClick={() => openQuestModal(card)}
                    className="orgpage_main_infocontainer_cardscontainer_cards_card_right_buttoncontainer_button"
                  >
                    View Submissions
                  </div>
                </div>

                {/* <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_date">
                    Application ends on {(card.expiryDate)}
                  </div> */}
              </div>
            </div>
            <div>
              <div className="orgpage_main_infocontainer_cardscontainer_cards_card_bottom">
                <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_top">
                  <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_top_type">
                    {card.limit === -1
                      ? "Unlimited Submissions"
                      : `First ${card.limit} submissions`}
                  </div>
                  <div className="orgpage_main_infocontainer_cardscontainer_cards_card_left_details_top_amount">{`ZXP ${card.rewardXp}`}</div>
                </div>
                <div className="orgpage_main_infocontainer_cardscontainer_cards_card_right_tags">
                  {renderdomain_tagsWithDynamicLimit(card.tags, 30)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}