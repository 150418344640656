import React, { useState } from "react";
import ToggleGigCreate from "../../components/Toggles/ToggleGigCreate";
import deleteicon from "../../assets/images/delete.svg";

function RewardsPage({ formData, setFormData }) {
  const categoryOptions = ["ZXP"];
  const [togglePreview, setTogglePreview] = useState(true);

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, bountyCurrency: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = parseInt(value, 10); 
    const newValue = parsedValue >= 0 ? parsedValue : 0; 
    
    setFormData({ ...formData, [name]: newValue });
  };


  return (
    <div className="basicscomponent">
      <select
        name="category"
        value={formData.bountyCurrency}
        className="basicscomponent_rewardcurrency"
        onChange={handleCategoryChange}
      >
        {categoryOptions.map((option, id) => (
          <option value={option} key={id}>
            {option}
          </option>
        ))}
      </select>
        <div className="basicscomponent_rewardcontainer">
          <div className="flex justify-between">
            <div className="basicscomponent_rewardcontainer_rewardserial">
              Prize Value
            </div>
          </div>
          <input
            className="basicscomponent_rewardcontainer_input"
            name="rewardXp"
            placeholder="Enter XP earned for the quest"
            value={formData.rewardXp}
            type="number"
            onChange={(e) => handleInputChange(e)}
          />

        </div>

    </div>
  );
}

export default RewardsPage;
