import React, { useState } from "react";
import "./ContactUs.scss";
import Header from "../../components/Header/Header";
import applicationsuccess from "../../assets/images/applicationsuccess.svg";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    blockchainFoundation: "",
    organizationEmail: "",
    onboardingPurpose: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    setIsSubmitted(true);
  };
    const { enqueueSnackbar } = useSnackbar();
    const showErrorSnack = (message) => {
      enqueueSnackbar(message, {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 3000,
      });
    };
    const showSuccessSnack = (message) => {
      enqueueSnackbar(message, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 3000,
      });
    };
  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="contact"
    >
      {/* <Header /> */}

      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />

      {!isSubmitted ? (
        <div className="contact_main">
          <div className="contact_main_contact">Contact</div>
          <div className="contact_main_title">Onboarding.club</div>
          <div className="contact_main_subtitle">
            Get started building your ecosystem in a few easy steps
          </div>
          <div className="contact_main_container">
            <div className="contact_main_container_inputcontainer">
              <div className="contact_main_container_title">
                Which blockchain foundation are you?
              </div>
              <input
                className="contact_main_container_input"
                type="text"
                name="blockchainFoundation"
                placeholder="Enter name of your ecosystem/foundation"
                value={formData.blockchainFoundation}
                onChange={handleInputChange}
              />
            </div>
            <div className="contact_main_container_inputcontainer">
              <div className="contact_main_container_title">
                Enter organization email
              </div>
              <input
                className="contact_main_container_input"
                type="email"
                name="organizationEmail"
                placeholder="Enter organization email"
                value={formData.organizationEmail}
                onChange={handleInputChange}
              />
            </div>
            <div className="contact_main_container_inputcontainer">
              <div className="contact_main_container_title">
                What are you looking for with Onboarding.club?
              </div>
              <textarea
                rows="3"
                className="contact_main_container_input"
                name="onboardingPurpose"
                placeholder="Enter your purpose"
                value={formData.onboardingPurpose}
                onChange={handleInputChange}
              />
            </div>
            <div
              onClick={handleSubmit}
              className="contact_main_container_submit"
            >
              Submit
            </div>
          </div>
        </div>
      ) : (
        <div className="contact_success">
          <div className="contact_success_icon">
            <img src={applicationsuccess} alt="success" />
          </div>
          <div className="contact_success_title">
            Your application has been received by us!
          </div>
          <div className="contact_success_subtitle">
            You can book a slot with us from the Calendly link below or wait for
            our revert!
          </div>
          <div className="contact_success_submit">Book a Calendly Slot</div>
        </div>
      )}
    </div>
  );
}
