import React, { useEffect, useState } from "react";
import "./UserProfile.scss";
import Header from "../../components/Header/StellarHeader/Header";
import twitterc from "../../assets/images/twittercolored.svg";
import linked_inc from "../../assets/images/linkedincolored.svg";
import ToggleProfile from "../../components/Toggles/ToggleProfile";
import gigImage from "../../assets/images/gigimage.svg";

import { toFormData } from "axios";
import EditProfileModal from "./EditProfileModal";
import AddProjectModal from "./AddProjectModal";
import AddExperienceModal from "./AddExperienceModal";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import OBCApi from "../../api/api";

const cardsData = [
  {
    type: "Design",
    name: "App Redesign: Focus on UX & UI",
    tags: ["Figma", "UI/UX", "Product Research"],
    createdBy: "Sorobix",
    bountyAmount: "2300 XLM",
    description:
      "Redesign the IDE for Sorobix to include a project directory import structure and also talk about how it can change the narrative for user onbaording.",
    expiryDate: "October 31, 2023",
    createdDate: "August 31, 2023",
    icon: gigImage,
  },
  {
    type: "Design",
    name: "StellarX Exchange Redesign",
    tags: ["Figma", "UI/UX", "Redesign"],
    createdBy: "StellarX",
    bountyAmount: "1800 XLM",
    description:
      "Redesign the user interface and experience of the StellarX decentralized exchange platform. Enhance user engagement and trading experience.",
    expiryDate: "December 15, 2023",
    createdDate: "August 10, 2023",
    icon: gigImage,
  },
  {
    type: "Frontend",
    name: "Lobstr Wallet Frontend Development",
    tags: ["React", "Web3", "Stellar"],
    createdBy: "Lobstr",
    bountyAmount: "2200 XLM",
    description:
      "Develop the frontend of the Lobstr Stellar wallet application. Implement wallet management features and provide a seamless user experience.",
    expiryDate: "November 25, 2023",
    createdDate: "June 5, 2023",
    icon: gigImage,
  },
  {
    type: "Backend",
    name: "Stellar Anchor Backend Development",
    tags: ["Smart Contracts", "Development", "Stellar"],
    createdBy: "AnchorCorp",
    bountyAmount: "3200 XLM",
    description:
      "Develop backend infrastructure for Stellar anchors. Enable secure token issuance and management on the Stellar blockchain.",
    expiryDate: "December 10, 2023",
    createdDate: "July 5, 2023",
    icon: gigImage,
  },
  {
    type: "Content",
    name: "Stellar.org Educational Content",
    tags: ["Content Creation", "Stellar", "Tutorials"],
    createdBy: "Stellar.org",
    bountyAmount: "1500 XLM",
    description:
      "Create educational content for Stellar.org, including tutorials and guides about the Stellar blockchain for a global audience.",
    expiryDate: "November 20, 2023",
    createdDate: "February 25, 2022",
    icon: gigImage,
  },
  {
    type: "Full Stack",
    name: "StellarSwap Full Stack Development",
    tags: ["React", "Node.js", "Stellar"],
    createdBy: "StellarSwap",
    bountyAmount: "2800 XLM",
    description:
      "Develop a full-stack decentralized exchange (DEX) powered by Stellar. Implement frontend trading features and backend order matching.",
    expiryDate: "December 5, 2023",
    createdDate: "January 30, 2022",
    icon: gigImage,
  },
  {
    type: "Creative Writing",
    name: "Stellar Community Blog Posts",
    tags: ["Content Writing", "Stellar", "Blockchain"],
    createdBy: "StellarCommunity",
    bountyAmount: "1200 XLM",
    description:
      "Write engaging blog posts for the Stellar community. Cover topics related to Stellar updates, partnerships, and community events.",
    expiryDate: "November 15, 2023",
    createdDate: "October 10, 2023",
    icon: gigImage,
  },
  {
    type: "Others",
    name: "Stellar Community Ambassador",
    tags: ["Community", "Engagement", "Stellar"],
    createdBy: "CommunityLeader",
    bountyAmount: "900 XLM",
    description:
      "Become a Stellar Community Ambassador and engage with the community. Share knowledge, answer questions, and foster a strong Stellar community.",
    expiryDate: "November 1, 2023",
    createdDate: "October 8, 2023",
    icon: gigImage,
  },
];
const renderTagsWithDynamicLimit = (tags, maxCharCount) => {
  let currentCharCount = 0;
  let visibleTags = [];
  let hiddenTagsCount = 0;

  for (const tag of tags) {
    if (currentCharCount + tag.length <= maxCharCount) {
      currentCharCount += tag.length;
      visibleTags.push(tag);
    } else {
      hiddenTagsCount = tags.length - visibleTags.length;
      break;
    }
  }

  return (
    <>
      {visibleTags.map((tag, tagIndex) => (
        <span
          key={tagIndex}
          className="userprofile_main_right_cards_card_right_tags_tag"
        >
          {tag}
        </span>
      ))}
      {hiddenTagsCount > 0 && (
        <>
          <span className="userprofile_main_right_cards_card_right_tags_tag">
            +{hiddenTagsCount}
          </span>
        </>
      )}
    </>
  );
};

export default function UserProfile() {
  const [toggleGigs, setToggleGigs] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openProjectModal, setOpenProjectModal] = useState(false);
  const [openExperienceModal, setOpenExperienceModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [profileData, setProfileData] = useState({
    name: "Rishabh Keshan",
    username: "rishabhkeshan",
    about: "I'm a passionate developer.",
    skills: ["JavaScript", "React", "Node.js", "Software", "Creative"],
    wallets_connected: [
      { name: "Freighter", address: "", blockchain: "Stellar" },
    ],
    twitter: "keshanrishabh",
    telegram: "rishabhkeshan",
    linked_in: "rishabhkeshan",
    discord: "johndoe#1234",
    projects: [
      {
        project_name: "Hostelzz",
        project_url: "https://google.com",
        project_description: "Where to stay? We can find it for you.",
        technologies_used: ["HTML", "CSS", "JS", "Rust"],
      },
    ],
    experiences: [
      {
        companyName: "Hungama",
        role: "Product Manager",
        location: "Remote",
        startDate: "Feb 2022",
        endDate: "May 2023",
        description:
          "Worked on integrating the metaverse, added pipelines and new horizons to the sandbox escape.",
      },
      {
        companyName: "Capx",
        role: "SDE Intern",
        location: "Bangalore",
        startDate: "Feb 2022",
        endDate: "May 2023",
        description:
          "Worked on integrating the metaverse, added pipelines and new horizons to the sandbox escape.",
      },
    ],
    Gigs: [{}],
  });

  useEffect(() => {
    if (!localStorage.getItem("jwt")) {
      //TODO: navigate to home/login
    }
    console.log(localStorage.getItem("jwt"));
    setShowLoading(true);
    fetchUserProfile();
  }, []);
  const fetchUserProfile = () => {
    const api = new OBCApi(localStorage.getItem("jwt"), "");
    api
      .getUserProfile()
      .then((res) => {
        setShowLoading(false);
        console.log(res.data.data);
        setProfileData(res.data.data.user);
      })
      .catch((err) => {
        setShowLoading(false);
        showErrorSnack(err.message);
      });
  };
  const showErrorSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  const showSuccessSnack = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      preventDuplicate: true,
      autoHideDuration: 3000,
    });
  };
  return (
    <div
      style={{ filter: showLoading ? "blur(20px)" : "none" }}
      className="userprofile"
    >
      {/* <Header /> */}

      <Loader showLoading={showLoading} setShowLoading={setShowLoading} />

      <div className="userprofile_main">
        <div className="userprofile_main_left_outer_outer">
          <div className="userprofile_main_left_outer">
            <div className="userprofile_main_left">
              <div className="userprofile_main_left_top">
                <div className="userprofile_main_left_profileimagecontainer">
                  {profileData.name ? profileData.name[0].toUpperCase() : ""}
                </div>
              </div>
              <div className="userprofile_main_left_namecontainer">
                <div className="userprofile_main_left_namecontainer_name">
                  {profileData.name}
                </div>{" "}
                <div
                  onClick={() => {
                    setOpenEditModal(true);
                  }}
                  className="userprofile_main_left_namecontainer_editbtn"
                >
                  Edit Profile
                </div>{" "}
              </div>
              <div className="userprofile_main_left_username">
                @{profileData.username}
              </div>
              <div className="userprofile_main_left_socialcontainer">
                <div className="userprofile_main_left_socialcontainer_social">
                  <img src={twitterc} alt="twitter" />
                  <div className="userprofile_main_left_socialcontainer_social_text">
                    {profileData.twitterHandle}
                  </div>
                </div>
                {/* <div className="userprofile_main_left_socialcontainer_social">
                  <img src={linked_inc} alt="linked_in" />
                  <div className="userprofile_main_left_socialcontainer_social_text">
                    {profileData.linked_in}
                  </div>
                </div> */}
              </div>

              <div className="userprofile_main_left_infocontainer">
                <div className="userprofile_main_left_infocontainer_title">
                  Tags
                </div>
                <div className="userprofile_main_left_infocontainer_tags">
                  {profileData?.tags?.map((skill, index) => (
                    <div
                      key={index}
                      className="userprofile_main_left_infocontainer_tags_tag"
                    >
                      {skill}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="userprofile_main_right_outer_outer">
          <div className="userprofile_main_right_outer">
            <div className="userprofile_main_right">
              <ToggleProfile
                toggleGigs={toggleGigs}
                setToggleGigs={setToggleGigs}
              />
              {toggleGigs ? (
                <div className="userprofile_main_right_scrollcontainer">
                  <div className="userprofile_main_right_infocontainer">
                    <div className="userprofile_main_right_infocontainer_title">
                      About
                    </div>
                    <div className="userprofile_main_right_infocontainer_value">
                      {profileData.about}
                    </div>
                  </div>
                  <div className="userprofile_main_right_workcontainer">
                    <div className="userprofile_main_right_workcontainer_titlecontainer">
                      <div className="userprofile_main_right_workcontainer_titlecontainer_title">
                        Work
                      </div>
                      <div
                        onClick={() => {
                          setOpenProjectModal(true);
                        }}
                        className="userprofile_main_right_workcontainer_titlecontainer_add"
                      >
                        +
                      </div>
                    </div>
                    <div className="userprofile_main_right_workcontainer_cards">
                      {profileData.projects.map((project, index) => (
                        <div
                          key={index}
                          className="userprofile_main_right_workcontainer_cards_card"
                        >
                          <a
                            href={project.project_url}
                            target="_blank"
                            rel="noreferrer"
                            className="userprofile_main_right_workcontainer_cards_card_title"
                          >
                            {project.project_name}
                          </a>
                          <div className="userprofile_main_right_workcontainer_cards_card_details">
                            <div className="userprofile_main_right_workcontainer_cards_card_details_desc">
                              {project.project_description}
                            </div>
                            <div className="userprofile_main_right_workcontainer_cards_card_details_tags">
                              {project.technologies_used.map((tech, index) => (
                                <div
                                  key={index}
                                  className="userprofile_main_right_workcontainer_cards_card_details_tags_tag"
                                >
                                  {tech}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="userprofile_main_right_expcontainer">
                    <div className="userprofile_main_right_expcontainer_titlecontainer">
                      <div className="userprofile_main_right_expcontainer_titlecontainer_title">
                        Experience
                      </div>
                      <div
                        onClick={() => {
                          setOpenExperienceModal(true);
                        }}
                        className="userprofile_main_right_expcontainer_titlecontainer_add"
                      >
                        +
                      </div>
                    </div>
                    <div className="userprofile_main_right_expcontainer_cards">
                      {profileData.experiences.map((exp, index) => (
                        <div
                          key={index}
                          className="userprofile_main_right_expcontainer_cards_card"
                        >
                          <div className="userprofile_main_right_expcontainer_cards_card_top">
                            <div className="userprofile_main_right_expcontainer_cards_card_top_left">
                              <div className="userprofile_main_right_expcontainer_cards_card_title">
                                {exp.role}
                              </div>
                              <div className="userprofile_main_right_expcontainer_cards_card_subtitle">
                                {exp.companyName}
                              </div>
                              <div className="userprofile_main_right_expcontainer_cards_card_workdet">
                                <div className="userprofile_main_right_expcontainer_cards_card_workdet_1">
                                  {exp.location}
                                </div>
                                <div className="userprofile_main_right_expcontainer_cards_card_workdet_2">
                                  <svg
                                    width="3"
                                    height="4"
                                    viewBox="0 0 3 4"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="1.5"
                                      cy="1.75"
                                      r="1.5"
                                      fill="#484848"
                                    />
                                  </svg>
                                </div>
                                <div className="userprofile_main_right_expcontainer_cards_card_workdet_3">{`${exp.startDate} - ${exp.endDate}`}</div>
                              </div>
                            </div>
                          </div>
                          <div className="userprofile_main_right_expcontainer_cards_card_details">
                            <div className="userprofile_main_right_expcontainer_cards_card_details_desc">
                              {exp.description}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="userprofile_main_right_scrollcontainer">
                  <div className=" userprofile_main_right_cards">
                    {cardsData.map((card, index) => (
                      <div
                        key={index}
                        className="userprofile_main_right_cards_card"
                      >
                        <div className="flex flex-col lg:flex-row  gap-4">
                          <div className="userprofile_main_right_cards_card_left">
                            <div className="userprofile_main_right_cards_card_left_image">
                              <img src={card.icon} alt="gig" />
                            </div>
                            <div className="userprofile_main_right_cards_card_left_details">
                              <div className="userprofile_main_right_cards_card_left_details_name">
                                {" "}
                                {card.name}
                              </div>
                              <div className="userprofile_main_right_cards_card_left_details_by">
                                by {card.createdBy}
                              </div>
                              <div className="userprofile_main_right_cards_card_left_details_desc">
                                {" "}
                                {card.description}
                              </div>
                            </div>
                          </div>
                          <div className="userprofile_main_right_cards_card_right">
                            <div className="userprofile_main_right_cards_card_right_submission">
                              Applied
                            </div>

                            <div className="userprofile_main_right_cards_card_right_date">
                              Submitted On: {card.createdDate}
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="userprofile_main_right_cards_card_left_details_top">
                            <div className="userprofile_main_right_cards_card_left_details_top_type">
                              {card.type}
                            </div>
                            <div className="userprofile_main_right_cards_card_left_details_top_amount">{`🏆 ${card.bountyAmount}`}</div>
                          </div>
                          <div className="userprofile_main_right_cards_card_right_tags">
                            {renderTagsWithDynamicLimit(card.tags, 30)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div> */}
      </div>
      {openEditModal && (
        <EditProfileModal
          profileData={profileData}
          onClose={() => {
            setOpenEditModal(false);
            fetchUserProfile();
          }}
        />
      )}
      {openProjectModal && (
        <AddProjectModal
          onClose={() => {
            setOpenProjectModal(false);
          }}
        />
      )}
      {openExperienceModal && (
        <AddExperienceModal
          onClose={() => {
            setOpenExperienceModal(false);
          }}
        />
      )}
    </div>
  );
}
